import { createSlice } from "@reduxjs/toolkit";

const name = "Encounter";

export const initialEncounterState = {
  // This will hold the current encounter whether it is created or already existing for an appointment.
  encounter: {},
  encounterSuccess: null,
  encounterByPatientSuccess: null,
  encounterByAppointmentSuccess: null,
  encounterMessage: "",
  encounterByPatientData: [],
  isEncounterLoading: false,
  isEncounterByPatientLoading: false,
  isEncounterByAppointmentLoading: false,
};

export const EncounterSlice = createSlice({
  name,
  initialState: initialEncounterState,
  reducers: {
    encounter: (state) => {
      state.isEncounterLoading = true;
    },
    encounterByPatient: (state) => {
      state.isEncounterByPatientLoading = true;
    },
    encounterByAppointment: (state) => {
      state.isEncounterByAppointmentLoading = true;
    },
    encounterSuccess: (state, action) => {
      state.encounter = action.payload;
      state.encounterSuccess = true;
      state.isEncounterLoading = false;
      state.encounterMessage = "Encounter created successfully";
    },
    encounterByPatientSuccess: (state, action) => {
      state.isEncounterByPatientLoading = false;
      state.encounterByPatientSuccess = true;
      state.encounterByPatientData = action.payload?.results;
    },
    encounterByAppointmentSuccess: (state, action) => {
      state.isEncounterByAppointmentLoading = false;
      state.encounterByAppointmentSuccess = true;
      state.encounter = action.payload;
    },
    encounterByIdSuccess: (state, action) => {
      state.encounter = action.payload;
    },
    encounterFail: (state) => {
      state.encounterSuccess = false;
      state.isEncounterLoading = false;
      state.encounterMessage = "Unable to create encounter";
    },
    encounterByPatientFail: (state) => {
      state.isEncounterByPatientLoading = false;
      state.encounterByPatientSuccess = false;
      state.encounterByPatientData = [];
    },
    encounterByAppointmentFail: (state) => {
      state.isEncounterByAppointmentLoading = false;
      state.encounterByAppointmentSuccess = false;
      state.encounterByAppointmentData = {};
    },
    clearEncounterData: (state) => {
      state.encounter = {};
      state.encounterMessage = "";
      state.encounterSuccess = null;
      state.isEncounterLoading = false;
    },
    clearEncounterByPatientData: (state) => {
      state.encounterByPatientData = [];
      state.encounterByPatientSuccess = null;
      state.isEncounterByPatientLoading = false;
    },
  },
});

export const { encounter, clearEncounterData, clearEncounterByPatientData } =
  EncounterSlice.actions;

export default EncounterSlice.reducer;

export const createOrUpdateEncounter = (data, method, encounterId) => ({
  payload: {
    apiName: name,
    data,
    method: data?.sign_close_ind === "c" ? "post" : method,
    formData: false,
    url:
      data?.sign_close_ind === "c"
        ? `api/encounters/${encounterId}/close/`
        : method !== "POST"
          ? `/api/encounters/${encounterId}/`
          : `/api/encounters/`,
  },
  type: "encounter",
});

export const getEncounterByPatient = (patientId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/encounters/`,
  },
  type: "encounterByPatient",
});

export const getEncounterByAppointment = (appointmentId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/encounters/search_by_appointment_id/?appointment_id=${appointmentId}`,
  },
  type: "encounterByAppointment",
});

export const getEncounterById = (encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/encounters/${encounterId}/`,
  },
  type: "encounterById",
});
