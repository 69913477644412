import { Box, Grid } from "@mui/material";
import DatePickerInput from "components/common/FormComponents/DatePickerInput";
import Text from "components/common/Typography/Text";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import {
  appointmentRightSide,
  innerTitleDateSearchBox,
  timerBox,
  titleDateBox,
  titleDateSearchBox,
  upperHeaderDatePickerStyling,
  upperHeaderTimeStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import fourboxActive from "../../../assets/svg/light/boxIcon.svg";
import threeLinesInActive from "../../../assets/svg/light/threelineIcon.svg";
import fourBoxInActive from "../../../assets/svg/colored/fourBoxInActive.svg";
import threeLinesActive from "../../../assets/svg/colored/threeLinesActive.svg";

const UpperHeader = ({
  title = "Scheduled Patients",
  isVerticalView,
  setIsVerticalView,
  date,
  // Set this prop to true if you want to show the date picker
  setDate,
}) => {
  const [time, setTime] = useState(moment().format("H : mm"));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("H : mm"));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={titleDateSearchBox}>
      <Grid container sx={innerTitleDateSearchBox}>
        <Grid
          item
          xs={12}
          sm={9}
          md={8}
          lg={4}
          xl={5}
          xxl={5}
          sx={titleDateBox}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}>
            <Text variant="h1" marginRight="20px">
              <span data-testid="main-scheduled-patient-heading">{title}</span>
            </Text>
            {setDate && (
              <Grid>
                <DatePickerInput
                  fontWeight="400"
                  setDate={setDate}
                  data-testid="date-picker"
                  defaultDate={
                    localStorage.getItem("scheduledPatientDate")
                      ? moment(localStorage.getItem("scheduledPatientDate"))
                      : moment(date)
                  }
                  datePickerStyling={upperHeaderDatePickerStyling}
                />
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid sx={upperHeaderTimeStyling}>
          <Box sx={timerBox} data-testid="timer-box">
            {time}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
          <Box data-testid="view-buttons-main-box" sx={appointmentRightSide}>
            {setIsVerticalView && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  onClick={() => setIsVerticalView(true)}
                  alt="horitzontal"
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    padding: "2px",
                    width: "25px",
                    height: "25px",
                  }}
                  src={!isVerticalView ? threeLinesInActive : threeLinesActive}
                  data-testid={`vertical-${!isVerticalView ? "inActive" : "active"}-view-button-id`}
                />
                <img
                  src={isVerticalView ? fourBoxInActive : fourboxActive}
                  alt="Vertical"
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    width: "32px",
                    height: "32px",
                  }}
                  data-testid={`horizontal-${isVerticalView ? "inActive" : "active"}-view-button-id`}
                  onClick={() => setIsVerticalView(false)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpperHeader;
