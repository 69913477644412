import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("Current password is required"),
  new_password: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|;:'",.<>?/\\~`[\]=-]).*$/,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character",
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});
