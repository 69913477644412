import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeLocation: {},
  isLocationsLoading: false,
  count: 0,
  next: null,
  previous: null,
  locations: [],
};

const name = "locations";

export const locationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    getLocations: (state, action) => {
      state.isLocationsLoading = true;
    },
    getLocationsSuccess: (state, action) => {
      state.isLocationsLoading = false;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.locations = action.payload.results.map((s) => ({
        label: s.location_name,
        value: s.id,
      }));
    },
    getLocationsFail: (state, action) => {
      state.isLocationsLoading = false;
      state.count = 0;
      state.next = null;
      state.previous = null;
      state.locations = null;
    },
  },
});

export const { getLocations, setActiveLocation } = locationsSlice.actions;

export default locationsSlice.reducer;

export const getLocationsList = () => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/locations/`,
  },
  type: "getLocations",
});
