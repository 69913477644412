import { Skeleton } from "@mui/material";

const SkeletonWrapper = ({
  id,
  children,
  variant,
  condition,
  multipleCount = 1,
  props,
  sx = {},
}) => {
  return (
    <>
      {condition ? (
        children
      ) : multipleCount > 1 ? (
        Array(multipleCount)
          .fill("")
          .map((s, i) => (
            <Skeleton
              sx={sx}
              key={i}
              data-testid={id}
              variant={variant}
              animation={"wave"}
              {...(multipleCount === props.length
                ? { ...props[i] }
                : { ...props[0] })}
            />
          ))
      ) : (
        <Skeleton
          sx={sx}
          data-testid={id}
          animation={"wave"}
          {...props[0]}
          variant={variant}
        />
      )}
    </>
  );
};

export default SkeletonWrapper;
