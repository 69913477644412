import { createSlice } from "@reduxjs/toolkit";

export const initialLabLocationsState = {
  labLocationsData: [],
  labLocationsDataOptions: [],
  labLocationsSuccess: null,
  labLocationsIsLoading: false,
};

const name = "labLocations";

export const labLocationsSlice = createSlice({
  name,
  initialState: initialLabLocationsState,
  reducers: {
    getLabLocations: (state, action) => {
      state.labLocationsIsLoading = true;
    },
    getLabLocationsSuccess: (state, action) => {
      state.labLocationsSuccess = true;
      state.labLocationsIsLoading = false;
      state.labLocationsData = action.payload.results;
      state.labLocationsDataOptions = action.payload?.results?.map((s) => ({
        label: s?.lab_location_name,
        value: s?.id,
      }));
    },
    getLabLocationsFail: (state, action) => {
      state.labLocationsSuccess = false;
      state.labLocationsData = [];
      state.labLocationsIsLoading = false;
      state.labLocationsDataOptions = [];
    },
    clearLabLocationsData: (state, action) => {
      state.labLocationsSuccess = null;
      state.labLocationsData = [];
      state.labLocationsIsLoading = false;
      state.labLocationsDataOptions = [];
    },
  },
});

export const { clearLabProvidersData } = labLocationsSlice.actions;

export default labLocationsSlice.reducer;

export const getLabLocationsList = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/lablocation/?search=${id}`,
  },
  type: "getLabLocations",
});
