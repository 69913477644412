import { useTheme } from "@emotion/react";
import {
  Box,
  Menu,
  MenuItem,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import profileAvatar from "assets/images/profileAvatar.png";
import RemoveTeamMemberModal from "components/common/Modal/RemoveTeamMemberModal";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAdminStatuses,
  softClearUpdateStatuses,
} from "redux/features/Admin/AdminSlice";
import { dropzoneStyles } from "styles";
import elipses from "utils/ellipsis";
import { toCapitalize } from "utils/toCapitalize";

const Strips = ({
  id = "",
  name,
  role,
  profilePic,
  options,
  selectedID,
  setSelectedID,
  setOpenEditModal,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getBoxWidth = () => {
    return {
      width: "99%",
      [theme.breakpoints.up("md")]: {
        width: "269px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "279px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "299px",
      },
    };
  };

  let stripMainBox = {
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
    background: "#F7F7FE",
    borderRadius: "14px",
    padding: "9px",
    border: "1px solid #e2e2e4",
    margin: "4px 10px 5px 0px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    ...getBoxWidth(),
    [`@media (min-width: 320px) and (max-width:992px)`]: {
      width: "100%",
    },
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      padding: "9px",
      fontSize: "12px",
    },
  }));

  const onMenuListClick = () => {
    setOpenModal(true);
    handleMenuClose();
  };
  const handleEditClick = () => {
    selectedID !== id && dispatch(clearAdminStatuses());
    selectedID === id && dispatch(softClearUpdateStatuses());
    setSelectedID(id);
    setOpenEditModal(true);
    handleMenuClose();
  };

  return (
    <Box sx={stripMainBox}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {profilePic ? (
          <img
            data-testid={name + role}
            src={profilePic}
            style={{
              height: "60px",
              width: "60px",
              backgroundSize: "contain",
              objectFit: "cover",
              objectPosition: "50% 0",
              borderRadius: "100%",
            }}
            alt=""
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div style={dropzoneStyles}>
              <img
                alt="User Pic"
                data-testid={name + role}
                src={profileAvatar}
                id="profile-image1"
                height="70"
              />
            </div>
          </div>
        )}
        <Box
          sx={{
            height: "99%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <BootstrapTooltip title={name} placement={"top-start"}>
            <div>
              <Text
                id={name}
                variant="h3"
                color="black"
                title={name}
                margin={"0px"}>
                {elipses(toCapitalize(name), 17) +
                  `${userId === id ? "(me)" : ""}`}{" "}
              </Text>
            </div>
          </BootstrapTooltip>
          <Text variant="span" fontSize="12px" fontWeight="400" marginY={0.7}>
            {role ?? "Nephrolytics"}
          </Text>
        </Box>
      </Box>
      <Box>
        {userId !== id && (
          <img
            data-testid="remove-member-icon"
            src={options}
            onClick={handleMenuOpen}
          />
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}>
          <MenuItem onClick={() => onMenuListClick()}>Remove</MenuItem>
          <MenuItem onClick={() => handleEditClick()}>Edit</MenuItem>
        </Menu>
      </Box>
      <RemoveTeamMemberModal
        open={openModal}
        setOpen={setOpenModal}
        userId={id}
        name={name}
      />
    </Box>
  );
};

export default Strips;
