// Given an object of values and an object of initial values, return an object of dirty values
// Uses JSON.stringify to compare values to cover multiple data types
export function getDirtyValues(values, initialValues, mappers) {
  const dirtyValues = {};
  for (const key in initialValues) {
    // If a mapper exists for the key, use it to map the values back to the API format.
    const mapFn = mappers[key];
    let valuesToCheck = values[key];

    // If a mapper exists, use it to map the value back to the API format.
    if (mapFn) {
      valuesToCheck = mapFn(values[key]);
    }

    if (Array.isArray(valuesToCheck)) {
      // If the field is an array then loop through the array and compare each value with the initial value.
      dirtyValues[key] = valuesToCheck.filter((valueToCheck) => {
        const matchingValue = initialValues[key]?.find(
          (x) => x.id?.toString() === valueToCheck.id,
        );

        if (matchingValue) {
          return Object.entries(valueToCheck).some(([key, value]) => {
            return value?.toString() !== matchingValue[key]?.toString();
          });
        }

        return true;
      });
    } else {
      if (
        JSON.stringify(valuesToCheck) !== JSON.stringify(initialValues[key])
      ) {
        dirtyValues[key] = valuesToCheck;
      }
    }
  }
  return dirtyValues;
}

export function removeEmptyValuesFromObject(values) {
  const newValues = { ...values };
  Object.keys(newValues).forEach((key) => {
    if (newValues[key] === "") {
      delete newValues[key];
    }
  });
  return newValues;
}
