import { LoadingButton } from "@mui/lab";
const Button = ({
  id = "",
  onClick,
  text,
  isLoading = false,
  disabled,
  leftSide = false,
  rightSide = false,
  sx = {},
  iconDetails = { allowIcon: false, icon: null },
  variant = "contained",
  color = "primary",
}) => {
  let { allowIcon, icon } = iconDetails;

  return (
    <LoadingButton
      data-testid={id}
      sx={{
        ...sx,
      }}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled || isLoading}
      color={color}
      variant={variant}>
      {leftSide && allowIcon ? (
        <img
          alt={icon}
          src={icon}
          style={{ padding: "0px 5px 0px 5px", height: "28px", width: "28px" }}
        />
      ) : (
        ""
      )}
      {text}
      {rightSide && allowIcon ? (
        <img
          alt={icon}
          src={icon}
          style={{ padding: "0px 5px 0px 5px", height: "28px", width: "28px" }}
        />
      ) : (
        ""
      )}
    </LoadingButton>
  );
};
export default Button;
