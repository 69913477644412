import { Box } from "@mui/material";
import Button from "components/common/Button/Button";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import TimerBox from "components/common/TimerBox/TimerBox";
import Text from "components/common/Typography/Text";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { toCapitalize } from "utils/toCapitalize";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  postRecordingData,
  saveDictation,
} from "redux/features/AmbientAI/RecordingSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  convertBlobToM4A,
  pauseRecording,
  resumeRecording,
  startRecording,
  stopRecording,
} from "utils/recording";
import recordingAnime from "../../../../assets/images/recordingAnime.gif";
import rightArrow from "../../../../assets/svg/light/rightArrow.svg";
import pauseIcon from "../../../../assets/svg/light/pause-icon.svg";
import resumeIcon from "../../../../assets/svg/light/resume-icon.svg";
import { useStopwatch } from "react-timer-hook";
import AvatarPic from "../../../../assets/images/profileAvatar.png";
import { PatientIntakeFormModal } from "../PatientIntakeForm/PatientIntakeFormModal";
import { IntakeFormProvider } from "../PatientIntakeForm/common/IntakeFormProvider";
import {
  autoRecordFeature,
  isFeatureEnabled,
} from "../../../../utils/featureFlags";

const SCDHeader = forwardRef(
  (
    {
      setIsPostCalled,
      patientId,
      setOpen,
      startAppointment,
      setStartAppointment,
      setPastVisit,
    },
    ref,
  ) => {
    const chunks = useRef([]);
    const [viewWidth, setViewWidth] = useState(window.innerWidth);
    const mediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    const [recordedUrl, setRecordedUrl] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [recordingEnded, setRecordingEnded] = useState(false);
    const [isRecordingPaused, setIsRecordingPaused] = useState(false);
    const [recordingTimestamps, setRecordingTimestamps] = useState([]);
    const [intakeOpen, setIntakeOpen] = useState(false);

    /*State to stop auto-recording if the flag is
     false and show button to start recording by*/
    const [enableManualRecording, setEnableManualRecording] = useState(false);

    const { encounter, isEncounterByAppointmentLoading } = useSelector(
      (state) => state.encounter,
    );
    const { getSpecificPatientData, getSpecificPatientSuccess } = useSelector(
      (state) => state.specificPatient,
    );
    const { flagsData } = useSelector((state) => state.getFlags);
    const { appointmentData } = useSelector(
      (state) => state.specificAppointment,
    );
    const dispatch = useDispatch();
    const { listening, finalTranscript, resetTranscript } =
      useSpeechRecognition();

    const startListeningHandler = () => {
      SpeechRecognition.startListening({ continuous: true });
    };

    const stopListeningHandler = () => {
      SpeechRecognition.stopListening();
    };

    const handleListen = () => {
      if (!listening) {
        startListeningHandler();
      } else {
        stopListeningHandler();
      }
    };

    useEffect(() => {
      if (finalTranscript !== "") {
        dispatch(saveDictation(finalTranscript));
        resetTranscript();
      }
    }, [finalTranscript, resetTranscript]);

    useEffect(() => {
      if (recordedUrl && !isRecording) {
        convertBlobToM4A(recordedUrl)
          .then((m4aBlob) => {
            // Creating Form Data
            let formData = new FormData();
            var file = new File([m4aBlob], "audio_recording.mp3", {
              type: "audio/mp3",
            });
            formData.append("recording_file", file);
            formData.append("times", JSON.stringify(recordingTimestamps));

            // Dispatch postRecordingData with ecounterId and FormData
            setIsPostCalled("called");
            dispatch(postRecordingData(encounter?.id, formData));
          })
          .catch((error) => {
            console.error("Error:", error); // eslint-disable-line no-console
          });
      }
    }, [isRecording, recordedUrl]);

    useEffect(() => {
      const handleResize = () => {
        setViewWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const {
      seconds,
      minutes,
      hours,
      start: startTimer,
      pause: pauseTimer,
      reset: resetTimer,
    } = useStopwatch({ autoStart: false });

    const handleStartApp = () => {
      handleListen();
      startRecording(
        setRecordedUrl,
        setIsRecording,
        mediaStream,
        mediaRecorder,
        chunks,
      );
      setRecordingTimestamps((prev) => [
        ...prev,
        {
          type: "start",
          time: new Date(),
        },
      ]);
    };

    function startRecordingProcess() {
      setStartAppointment(true);
      if (isFeatureEnabled(flagsData, autoRecordFeature)) {
        handleStartApp();
        startTimer();
      } else {
        setEnableManualRecording(true);
      }
    }

    useImperativeHandle(ref, () => ({
      startRecordingProcessFunc: startRecordingProcess,
    }));

    return (
      <>
        <Box
          sx={{
            backgroundColor: "#FFFFFFBF",
            borderRadius: "24px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            padding: "10px 15px",
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                flex: viewWidth < 992 ? 4 : 2,
                alignItems: "center",
                flexWrap: "wrap",
              }}>
              <Box
                sx={{
                  marginRight: "10px",
                  height: "64px",
                }}>
                <SkeletonWrapper
                  id={"patient-detail-header-name"}
                  condition={getSpecificPatientSuccess}
                  variant="circular"
                  props={[
                    SkeletonPropsStyleGenerator("80px", "80px", "", "10px"),
                  ]}>
                  <img
                    src={AvatarPic}
                    alt=""
                    style={{
                      borderRadius: "100%",
                      width: "64px",
                      height: "64px",
                    }}
                    data-testid="scd-header-patient-img-id"
                  />
                </SkeletonWrapper>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}>
                  <SkeletonWrapper
                    id={"patient-detail-header-name"}
                    condition={getSpecificPatientSuccess}
                    props={[SkeletonPropsStyleGenerator("100px", "45px")]}>
                    <Text
                      id={"scd-patient-name"}
                      variant="h1"
                      color={"black"}
                      marginRight="10px">
                      {getSpecificPatientData?.first_name
                        ? `${getSpecificPatientData?.first_name}`
                        : ""}
                    </Text>
                    <span
                      style={{ fontSize: "1.375rem", lineHeight: "2.063rem" }}>
                      {getSpecificPatientData?.last_name
                        ? getSpecificPatientData?.last_name
                        : ""}
                    </span>
                  </SkeletonWrapper>
                  <SkeletonWrapper
                    id={"patient-detail-header-name"}
                    condition={getSpecificPatientSuccess}
                    props={[SkeletonPropsStyleGenerator("5px", "0px")]}>
                    <span style={{}} />
                  </SkeletonWrapper>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SkeletonWrapper
                    id={"patient-detail-header-gender"}
                    condition={getSpecificPatientSuccess}
                    props={[
                      SkeletonPropsStyleGenerator("100px", "45px", "", "10px"),
                    ]}>
                    <Text
                      id={"scd-patient-id"}
                      variant="bodyXs"
                      color={"lightBlack"}>
                      ID: {getSpecificPatientData?.emr_id ?? ""}
                    </Text>
                  </SkeletonWrapper>
                  <hr
                    style={{
                      height: "16px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <SkeletonWrapper
                    id={"patient-detail-header-gender"}
                    condition={getSpecificPatientSuccess}
                    props={[SkeletonPropsStyleGenerator("100px", "45px", "")]}>
                    <Text
                      id={"scd-patient-gender"}
                      variant="bodyXs"
                      color={"lightBlack"}>
                      {getSpecificPatientData?.gender
                        ? toCapitalize(getSpecificPatientData?.gender)
                        : ""}
                    </Text>
                  </SkeletonWrapper>
                  <hr
                    style={{
                      height: "16px",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                  <SkeletonWrapper
                    id={"patient-detail-header-age"}
                    condition={getSpecificPatientSuccess}
                    props={[SkeletonPropsStyleGenerator("100px", "45px", "")]}>
                    <Text
                      id={"scd-patient-age"}
                      variant="bodyXs"
                      color={"lightBlack"}>
                      {getSpecificPatientData?.birthdate
                        ? `${moment(getSpecificPatientData?.birthdate).format(
                            "YYYY-MM-DD",
                          )} ( ${moment().diff(
                            getSpecificPatientData?.birthdate,
                            "years",
                          )} y.o.)`
                        : ""}
                    </Text>
                  </SkeletonWrapper>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flex: 1,
                flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
              }}>
              <SkeletonWrapper
                id={"patient-detail-header-age"}
                condition={!isEncounterByAppointmentLoading}
                multipleCount={2}
                sx={{ marginLeft: "12px" }}
                props={[SkeletonPropsStyleGenerator("130px", "70px")]}>
                {startAppointment && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: viewWidth < 992 ? "wrap" : "nowrap",
                      justifyContent: "flex-end",
                    }}>
                    {!recordingEnded && (
                      <div style={{ fontSize: "16px", marginRight: "10px" }}>
                        <span>{hours}</span>:<span>{minutes}</span>:
                        <span>{seconds}</span>
                      </div>
                    )}
                    {isRecording && !isRecordingPaused && (
                      <>
                        <img
                          src={recordingAnime}
                          alt="anime file"
                          height={30}
                        />
                        <img
                          src={pauseIcon}
                          alt=""
                          data-testid="scd-header-mic-icon"
                          style={{
                            cursor: "pointer",
                            marginLeft: "8px",
                            marginRight: viewWidth < 992 ? "10px" : "",
                          }}
                          onClick={() => {
                            pauseRecording(
                              mediaStream,
                              mediaRecorder,
                              isRecordingPaused,
                              setIsRecordingPaused,
                            );
                            pauseTimer();
                            setRecordingTimestamps((prev) => [
                              ...prev,
                              {
                                type: "stop",
                                time: new Date(),
                              },
                            ]);
                          }}
                        />
                      </>
                    )}
                    {(isRecordingPaused || enableManualRecording) && (
                      <img
                        src={resumeIcon}
                        alt=""
                        data-testid="scd-header-mic-icon"
                        style={{
                          cursor: "pointer",
                          marginLeft: "8px",
                          marginRight: viewWidth < 992 ? "10px" : "",
                        }}
                        onClick={
                          enableManualRecording
                            ? () => {
                                handleStartApp();
                                startTimer();
                                setEnableManualRecording(false);
                              }
                            : () => {
                                resumeRecording(
                                  mediaStream,
                                  mediaRecorder,
                                  setIsRecording,
                                  setIsRecordingPaused,
                                );
                                startTimer();
                                setRecordingTimestamps((prev) => [
                                  ...prev,
                                  {
                                    type: "start",
                                    time: new Date(),
                                  },
                                ]);
                              }
                        }
                      />
                    )}
                    {viewWidth > 992 ? (
                      <hr
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          height: "52px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      id={"scd-end-visit-btn-id"}
                      text="End Visit"
                      sx={{
                        width: "150px",
                        padding: "0px 20px",
                        marginTop: viewWidth < 992 ? "10px" : "",
                      }}
                      rightSide
                      iconDetails={{ allowIcon: true, icon: rightArrow }}
                      onClick={() => {
                        setOpen(true);
                        stopListeningHandler();
                        stopRecording(
                          mediaStream,
                          mediaRecorder,
                          setIsRecordingPaused,
                          setIsRecording,
                        );
                        setIsRecording(false);
                        setRecordingTimestamps((prev) => [
                          ...prev,
                          {
                            type: "stop",
                            time: new Date(),
                          },
                        ]);
                        resetTimer();
                        setIsRecordingPaused(false);
                        setRecordingEnded(true);
                      }}
                    />
                  </Box>
                )}
                {!startAppointment && (
                  <>
                    <Button
                      text="Patient Intake"
                      variant="outlined"
                      sx={{
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setIntakeOpen(true);
                      }}
                    />
                    <Button
                      text="Review Past Visits"
                      variant="outlined"
                      sx={{
                        marginRight: "10px",
                      }}
                      id={"scd-review-past-note-btn-id"}
                      onClick={() => {
                        setPastVisit(true);
                      }}
                    />
                  </>
                )}
                {!startAppointment &&
                  encounter?.sign_close_ind !== "c" &&
                  encounter?.sign_close_ind !== "r" && (
                    <Button
                      onClick={() => startRecordingProcess()}
                      id={"scd-start-recording-btn-id"}
                      text={"Start Recording"}
                      rightSide
                      iconDetails={{ allowIcon: true, icon: rightArrow }}
                    />
                  )}
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>
        <IntakeFormProvider patientId={patientId}>
          <PatientIntakeFormModal open={intakeOpen} setOpen={setIntakeOpen} />
        </IntakeFormProvider>
      </>
    );
  },
);

function Timer({ time = "9:15", date = "" }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Text
        data-testid="scd-header-timer"
        sx={{
          padding: "10px",
          borderRadius: "16px",
          backgroundColor: "#D9FFE5",
          marginLeft: "8px",
          display: "flex",
          alignItems: "center",
        }}
        variant="bodyS">
        <AccessTimeIcon
          sx={{
            marginRight: "5px",
            width: "16px",
            height: "16px",
          }}
          data-testid={"scd-header-access-time-icon"}
        />
        Scheduled for {time}{" "}
        {moment().isSame(moment(date?.split("T")?.[0]), "day") ? "today" : ""}
      </Text>
    </Box>
  );
}

export default SCDHeader;
