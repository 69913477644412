// These tabs are for graph tabs in trends sections (for graphs)
import { Box, Checkbox, Tab, useTheme } from "@mui/material";
import { useState } from "react";
import { trendsVitalsButtonsStyle } from "styles/SCD/trendsStyle";

const CustomTabs = ({ label, colors, defaultChecked, setActiveGraphs }) => {
  let theme = useTheme();
  const [isChecked, setIsChecked] = useState(defaultChecked);

  function handleChange(checked, label) {
    checked ? setIsChecked(true) : setIsChecked(false);
    setActiveGraphs(checked, label);
  }

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "10px",
        marginBottom: "10px",
        flexDirection: "row",
        alignItems: "center",
      }}>
      <Checkbox
        sx={{
          margin: "2px",
          "& .MuiSvgIcon-root": {
            color: isChecked ? "#eef2ff" : "",
            background: isChecked ? "#4f46e5" : "",
            borderRadius: isChecked ? "5px" : "",
            fontSize: isChecked ? 18 : 20,
          },
          "&.Mui-checked": {
            color: isChecked ? "#4f46e5" : "",
          },
          "&.MuiCheckbox-icon": {
            color: "black !important",
          },
        }}
        defaultChecked={defaultChecked}
        onChange={(e) => handleChange(e?.target?.checked, label)}
      />
      <Tab label={label} sx={trendsVitalsButtonsStyle(label, theme)} />
      <div
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
        {colors?.map((c) => (
          <div
            style={{
              backgroundColor: c,
              width: "10px",
              height: "10px",
              margin: "3px",
              borderRadius: "100%",
            }}>
            {" "}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default CustomTabs;
