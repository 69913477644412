import { createSlice } from "@reduxjs/toolkit";

export const initialMedicinesState = {
  medicinesData: [],
  medicinesSuccess: null,
  medicinesIsLoading: false,
  medicinesSearchData: [],
  medicinesSearchSuccess: null,
  medicinesSearchIsLoading: false,
  updateMedicineSuccess: null,
  updateMedicineLoading: false,
  medicinesEncounterLoading: false,
  medicinesEncounterSuccess: null,
  medicinesEncounterData: [],
  medicinesEncounterDataOptions: [],
  deleteInProgress: false,
  deletedSuccess: false,
};

const name = "medicines";

export const medicinesSlice = createSlice({
  name,
  initialState: initialMedicinesState,
  reducers: {
    getMedicines: (state) => {
      state.medicinesIsLoading = true;
    },
    getSearchMedicine: (state) => {
      state.medicinesSearchIsLoading = true;
    },
    updateMedicine: (state) => {
      state.updateMedicineLoading = true;
      state.updateMedicineSuccess = null;
    },
    getMedicinesEncounter: (state) => {
      state.medicinesEncounterLoading = true;
    },
    getMedicinesSuccess: (state, action) => {
      state.medicinesSuccess = true;
      state.medicinesIsLoading = false;
      state.medicinesData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.drug_name,
            // id is going up
            value: s.dispensable_drug_id,
            description: s.purpose,
          }))
        : state.medicinesData;
    },

    getSearchMedicineSuccess: (state, action) => {
      state.medicinesSearchSuccess = true;
      state.medicinesSearchIsLoading = false;
      state.medicinesSearchData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.drug_name,
            value: s.dispensable_drug_id,
            description: s.purpose,
          }))
        : [];
    },
    updateMedicineSuccess: (state) => {
      state.updateMedicineSuccess = true;
      state.updateMedicineLoading = false;
    },
    getMedicinesEncounterSuccess: (state, action) => {
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterData = action.payload?.medicines?.filter(
        (s) => s.discontinued_on === null,
      );
      state.medicinesEncounterDataOptions = action.payload?.medicines
        ?.filter((s) => s.discontinued_on === null)
        .map((s) => s.drug_name);
      state.medicinesEncounterSuccess = true;
    },
    getMedicinesFail: (state) => {
      state.medicinesData = [];
      state.medicinesSuccess = false;
      state.medicinesIsLoading = false;
    },
    getSearchMedicineFail: (state) => {
      state.medicinesSearchData = [];
      state.medicinesSearchSuccess = false;
      state.medicinesSearchIsLoading = false;
    },
    updateMedicineFail: (state) => {
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
    },
    getMedicinesEncounterFail: (state) => {
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterData = [];
      state.medicinesEncounterDataOptions = [];
      state.medicinesEncounterSuccess = false;
    },
    //delete prescription
    deletePrescription: (state) => {
      state.deleteInProgress = true;
    },
    deletePrescriptionSuccess: (state) => {
      state.deleteInProgress = false;
      state.deletedSuccess = true;
    },
    deletePrescriptionFail: (state) => {
      state.deleteInProgress = false;
      state.deletedSuccess = false;
    },
    //clear states
    clearDeletePrescription: (state) => {
      state.deleteInProgress = false;
      state.deletedSuccess = false;
    },
    clearMedicinesListData: (state) => {
      state.medicinesData = [];
      state.medicinesSuccess = null;
      state.medicinesIsLoading = false;
    },
    clearMedicinesData: (state) => {
      state.medicinesData = [];
      state.medicinesSuccess = null;
      state.medicinesIsLoading = false;
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterSuccess = null;
      state.medicinesEncounterData = [];
      state.deleteInProgress = false;
      state.deletedSuccess = false;
      state.medicinesEncounterDataOptions = [];
    },
    clearMedicinesStates: (state) => {
      state.medicinesSuccess = null;
      state.medicinesIsLoading = false;
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterSuccess = null;
    },
    clearMedicineSearchData: (state) => {
      state.medicinesSearchSuccess = null;
      state.medicinesSearchIsLoading = false;
      state.medicinesSearchData = [];
    },
  },
});

export const {
  getmedicines,
  updateMedicines,
  getMedicinesEncounter,
  clearMedicinesStates,
  clearMedicinesData,
  clearMedicinesListData,
  clearMedicineSearchData,
  clearDeletePrescription,
} = medicinesSlice.actions;

export default medicinesSlice.reducer;

//for displaying the medicines into the dropdown
export const getMedicinesList = (searchQuery, patientId, signal) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/prescriptions/search/?drug_name=${searchQuery}`,
    signal: signal,
  },
  type: "getMedicines",
});

export const getPrescriptionByPatient = (patientId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/prescriptions/`,
  },
  type: "getMedicinesEncounter",
});

export const updatePrescriptionsByPatientId = (
  data,
  patientId,
  prescriptionId,
) => ({
  payload: {
    apiName: name,
    data,
    method: prescriptionId ? "PUT" : "POST",
    url: prescriptionId
      ? `/api/patients/${patientId}/prescriptions/${prescriptionId}/`
      : `/api/patients/${patientId}/prescriptions/`,
  },
  type: "updateMedicine",
});

export const deletePrescriptionsByPatientId = (patientId, prescriptionId) => ({
  payload: {
    apiName: name,
    method: "DELETE",
    url: `/api/patients/${patientId}/prescriptions/${prescriptionId}/`,
  },
  type: "deletePrescription",
});
