import { createSlice } from "@reduxjs/toolkit";

export const initialOrderMedicationsState = {
  orderMedicationsList: [],
  orderMedicationsIsLoading: false,
  specificOrderMedication: {},
};

const name = "orderMedications";

export const orderMedicationsSlice = createSlice({
  name,
  initialState: initialOrderMedicationsState,
  reducers: {
    getOrderMedications: (state, action) => {
      state.orderMedicationsIsLoading = true;
    },
    getMedicationById: (state, action) => {
      const id = action.payload;
      state.specificOrderMedication = "";
      state.specificOrderMedication =
        state.orderMedicationsList.find(
          ({ medicine_id }) => medicine_id === id,
        ) || {};
    },
    getOrderMedicationsSuccess: (state, action) => {
      state.orderMedicationsIsLoading = false;
      state.orderMedicationsList = "";
      state.orderMedicationsList = action.payload?.results || [];
    },
    getOrderMedicationsFail: (state, action) => {
      state.orderMedicationsList = [];
      state.orderMedicationsIsLoading = false;
    },
    clearOrderMedicationsData: (state) => {
      state.orderMedicationsIsLoading = false;
      state.specificOrderMedication = {};
    },
    clearAllOrderMedicationsStates: (state) => {
      state.orderMedicationsIsLoading = false;
      state.specificOrderMedication = {};
      state.orderMedicationsList = [];
    },
  },
});

export const {
  getOrderMedications,
  clearOrderMedicationsData,
  clearAllOrderMedicationsStates,
  getMedicationById,
} = orderMedicationsSlice.actions;

export default orderMedicationsSlice.reducer;

export const getOrderMedicationsList = (patientId, encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/prescriptions/?encounter_pk=${encounterId}`,
  },
  type: "getOrderMedications",
});
