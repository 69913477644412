import Cookies from "js-cookie";
import axios from "axios";

const HttpMethod = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
});

export async function getAxiosBaseOptions(method) {
  const baseURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : window.location.origin;

  if (!Cookies.get("csrftoken") && method !== HttpMethod.GET) {
    await axios.request({
      baseURL,
      method: HttpMethod.GET,
      url: "/api/csrf-token/",
    });
  }

  return {
    baseURL,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  };
}

// EX. {filter: {field: "name", operator: "contains", value: "John"}, sort: {field: "name", order: "asc"}}
export function parseQueryOptions(queryOptions) {
  const params = new URLSearchParams();
  if (queryOptions.filter) {
    params.append("filter", JSON.stringify(queryOptions.filter));
  }
  if (queryOptions.sort) {
    params.append("sort", JSON.stringify(queryOptions.sort));
  }
  return params.toString();
}

export async function apiGet(url, signal) {
  const method = HttpMethod.GET;
  const options = await getAxiosBaseOptions(method);
  return axios.request({
    ...options,
    method,
    url,
    signal,
  });
}

export async function apiPost(url, data) {
  const method = HttpMethod.POST;
  const options = await getAxiosBaseOptions(method);
  return axios.request({
    ...options,
    method,
    url,
    data: data,
  });
}

export async function apiPut(url, data) {
  const method = HttpMethod.PUT;
  const options = await getAxiosBaseOptions(method);
  return axios.request({
    ...options,
    method,
    url,
    data: data,
  });
}

export async function apiDelete(url) {
  const method = HttpMethod.DELETE;
  const options = await getAxiosBaseOptions(method);
  return axios.request({
    ...options,
    method,
    url: url,
  });
}
