import { forwardRef } from "react";

export const Person = forwardRef((props, ref) => {
  return (
    <svg
      ref={ref}
      {...props}
      width="24"
      height="24"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="icon/patient">
        <path
          fillRule="evenodd"
          d="M8.99991 9.64648C6.1258 9.64648 3.99023 11.7328 3.99023 14.0781V15.2799H14.0096V14.0781C14.0096 11.7328 11.874 9.64648 8.99991 9.64648ZM2.86523 14.0781C2.86523 11.0093 5.61182 8.52148 8.99991 8.52148C12.388 8.52148 15.1346 11.0093 15.1346 14.0781V15.6549C15.1346 16.0691 14.7988 16.4049 14.3846 16.4049H3.61523C3.20102 16.4049 2.86523 16.0691 2.86523 15.6549V14.0781Z"
        />
        <path
          fillRule="evenodd"
          d="M9.00006 6.81066C10.2431 6.81066 11.2508 5.80295 11.2508 4.55987C11.2508 3.31679 10.2431 2.30908 9.00006 2.30908C7.75698 2.30908 6.74927 3.31679 6.74927 4.55987C6.74927 5.80295 7.75698 6.81066 9.00006 6.81066ZM9.00006 7.93566C10.8645 7.93566 12.3758 6.42427 12.3758 4.55987C12.3758 2.69547 10.8645 1.18408 9.00006 1.18408C7.13566 1.18408 5.62427 2.69547 5.62427 4.55987C5.62427 6.42427 7.13566 7.93566 9.00006 7.93566Z"
        />
      </g>
    </svg>
  );
});
