import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGroupsLoading: false,
  groupsOptions: [],
};

const name = "options";

const excludedGroups = ["front desk representatives", "medical assistants"];

export const optionSlice = createSlice({
  name,
  initialState,
  reducers: {
    getGroups: (state, action) => {
      state.isGroupsLoading = true;
    },
    getGroupsSuccess: (state, action) => {
      state.isGroupsLoading = false;
      const { results = [] } = action.payload;
      state.groupsOptions = results?.reduce((acc, { id, name }) => {
        /* This check is only for the MVP because following are not included:
                (Front Desk Representatives & Medical Assistants) */
        if (!excludedGroups.includes(name.toLowerCase())) {
          acc.push({ value: id, label: name });
        }
        return acc;
      }, []);
    },
    getGroupsFail: (state, action) => {
      state.isGroupsLoading = false;
      state.groupsOptions = [];
    },
  },
});

export const { getGroups } = optionSlice.actions;

export default optionSlice.reducer;

export const getGroupsOptions = (query) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/groups/`,
  },
  type: "getGroups",
});
