import { createSlice } from "@reduxjs/toolkit";

export const patientApiName = "patients";

export const initialSchduledAppointmetsStateValue = {
  isLoading: false,
  isLoadingForSearch: false,
  isOverviewLoading: false,
  scheduleAppointmentsMessage: "",
  count: 0,
  next: "",
  previous: "",
  results: [],
  resultsForSearch: [],
  overviewResults: [],
};

export const scheduleAppointmentsSlice = createSlice({
  name: "patients",
  initialState: initialSchduledAppointmetsStateValue,
  reducers: {
    scheduleAppointments: (state, action) => {
      state.isLoading = true;
      state.scheduleAppointmentsMessage = "";
    },
    scheduleAppointmentsForSearch: (state, action) => {
      state.isLoadingForSearch = true;
    },
    scheduleAppointmentsOverview: (state, action) => {
      state.isOverviewLoading = true;
    },
    scheduleAppointmentsSuccess: (state, action) => {
      state.isLoading = false;
      state.count = action.payload.count;
      state.next = action.payload?.next;
      state.previous = action.payload?.previous;
      state.results = action.payload?.results;
      state.scheduleAppointmentsMessage =
        action.payload?.results?.length === 0 ? "No Appointments found" : "";
    },
    scheduleAppointmentsForSearchSuccess: (state, action) => {
      state.isLoadingForSearch = false;
      state.resultsForSearch = action.payload?.results;
    },
    scheduleAppointmentsOverviewSuccess: (state, action) => {
      state.isOverviewLoading = false;
      state.overviewResults = action.payload?.results;
    },
    scheduleAppointmentsFail: (state, action) => {
      state.isLoading = false;
      state.count = 0;
      state.next = "";
      state.previous = "";
      state.results = [];
      state.scheduleAppointmentsMessage = "Sorry unable to get appointments";
    },
    scheduleAppointmentsForSearchFail: (state, action) => {
      state.isLoadingForSearch = false;
      state.resultsForSearch = [];
    },
    scheduleAppointmentsOverviewFail: (state, action) => {
      state.isOverviewLoading = false;
      state.overviewResults = [];
    },
    clearScheduleAppointmentsMessage: (state, action) => {
      state.scheduleAppointmentsMessage = "";
    },
  },
});

export const {
  scheduleAppointmentsSuccess,
  scheduleAppointmentsFail,
  clearLoginMessage,
} = scheduleAppointmentsSlice.actions;

export default scheduleAppointmentsSlice.reducer;

export const getScheduledAppointments = (
  pageNo,
  date,
  location_id,
  account_id,
) => ({
  payload: {
    apiName: patientApiName,
    method: "get",
    url: `/api/appointments/scheduled/?date=${date}&location_id=${location_id}&account_id=${account_id}&page_size=15&page=${pageNo}`,
  },
  type: "scheduleAppointments",
});

export const getScheduledAppointmentsForSearch = (
  pageNo,
  date,
  location_id,
  account_id,
) => ({
  payload: {
    apiName: patientApiName,
    method: "get",
    url: `/api/appointments/scheduled/?date=${date}&location_id=${location_id}&account_id=${account_id}&page_size=15&page=${pageNo}`,
  },
  type: "scheduleAppointmentsForSearch",
});

export const getScheduledAppointmentsOverview = (
  data,
  pageNo,
  date,
  location_id,
  account_id,
) => ({
  payload: {
    apiName: patientApiName,
    data,
    method: "get",
    url: `/api/appointments/scheduled/?date=${date}&location_id=${location_id}&account_id=${account_id}&page_size=50&page=${pageNo}`,
  },
  type: "scheduleAppointmentsOverview",
});
