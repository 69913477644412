import { createSlice } from "@reduxjs/toolkit";

export const initialForgotPasswordState = {
  forgotPasswordSuccess: null,
  forgotPasswordIsLoading: false,
};

const name = "forgotPassword";

export const forgotPasswordSlice = createSlice({
  name,
  initialState: initialForgotPasswordState,
  reducers: {
    requestForgotPassword: (state, action) => {
      state.forgotPasswordIsLoading = true;
    },
    requestForgotPasswordSuccess: (state, action) => {
      state.forgotPasswordIsLoading = false;
      state.forgotPasswordSuccess = true;
    },
    requestForgotPasswordFail: (state, action) => {
      state.forgotPasswordSuccess = false;
      state.forgotPasswordIsLoading = false;
    },
    clearRequestForgotPassword: (state, action) => {
      state.forgotPasswordSuccess = null;
      state.forgotPasswordIsLoading = false;
    },
  },
});

export const { requestForgotPassword, clearRequestForgotPassword } =
  forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

export const userRequestForgotPassword = (data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/request-password-reset/`,
  },
  type: "requestForgotPassword",
});
