import { Alert, Box, Grid, useTheme } from "@mui/material";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addPharmacy,
  deletePharmacy,
  fetchPharmacies,
  searchPharmacies,
} from "apiClients/pharmacies";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPharmacyList } from "redux/features/Pharmacy/PharmacySlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { debounce } from "utils/debouncer";
import { Required } from "validations/validators";

const PharmacyModal = ({
  setIsOpenModal,
  selectedPharmacyValue,
  setSelectedPharmacyValue,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  let { patientId } = useParams();
  const queryClient = useQueryClient();

  const { getSpecificPatientData = {} } = useSelector(
    (state) => state.specificPatient,
  );

  /* because of aborting of previous calls
  maintaining loading state in component*/
  const [options, setOptions] = useState([]);
  const [zipCode, setZipCode] = useState(getSpecificPatientData?.zipcode);
  const [searchedText, setSearchedText] = useState("");
  const [apiError, setApiError] = useState(false);
  const [addedPharmacies, setAddedPharmacies] = useState(null);
  const [selectedPharmacy, setSelectedPharmacy] = useState(null);
  /* 
    state for dynamic count for the skeleton loaders
    on addition or deletion of the pharmacy
  */
  const [skeletonLoaderCount, setSkeletonLoaderCount] = useState(0);

  // Fetch the pharmacies data
  const {
    isLoading: isPharmaciesLoading,
    isFetching: isPharmaciesFetching,
    data: { results: pharmacyList } = {},
  } = useQuery({
    queryKey: ["pharmacies", patientId],
    queryFn: async () => await fetchPharmacies(patientId),
  });

  // Fetch the searched pharmacies data
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: isSearchedPharmaciesLoading,
  } = useInfiniteQuery({
    queryKey: ["searchPharmacies", patientId, searchedText, zipCode],
    queryFn: async ({ pageParam = 1, signal }) => {
      // Fetch paginated pharmacy data
      const response = await searchPharmacies(
        patientId,
        searchedText,
        zipCode,
        pageParam,
        signal,
      );

      // Transform results to match the Redux structure
      const transformedIntoOptions = response?.results?.map(
        ({ pharmacy_name, pharmacy_id, address, phone }) => ({
          value: address,
          id: pharmacy_id,
          phone,
          label: pharmacy_name,
        }),
      );

      return {
        results: transformedIntoOptions,
        nextPage: response.next, // Update with the API's next page info
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    enabled: Boolean(searchedText?.length > 2 && zipCode),
  });

  // Mutation to add the pharmacy
  const addPharmacyMutation = useMutation({
    mutationFn: async (data) => {
      return addPharmacy(patientId, data);
    },

    onSuccess: () => {
      dispatch(getPharmacyList(patientId));
      setIsOpenModal(false);
    },
  });

  // Mutation to delete the pharmacy
  const deletePharmacyMutation = useMutation({
    mutationFn: async (pharmacyId) => {
      return deletePharmacy(patientId, pharmacyId);
    },

    onSuccess: () => {
      dispatch(getPharmacyList(patientId));
      queryClient.invalidateQueries({ queryKey: ["pharmacies", patientId] });
    },

    onError: (error) => {
      increaseSkeletonLoaderCount();
      setApiError(
        error.response?.data?.detail || "Failed to delete the pharmacy.",
      );
    },
  });

  function increaseSkeletonLoaderCount() {
    setSkeletonLoaderCount((prev) => prev + 1);
  }

  const handleSearch = debounce((value) => {
    let trimmedValue = value?.trim();
    if (trimmedValue?.length > 2) {
      setOptions([]);
      if (zipCode) {
        setSearchedText(trimmedValue);
      }
    } else {
      setSearchedText("");
    }
  }, 400);

  const changeZipCode = debounce((e) => {
    // Clear the options of dropdown if the zip code is changed
    setZipCode(e.target.value);
    setOptions([]);
  }, 500);

  const onPharmacySelect = (e, v) => {
    if (v?.label) {
      // setting the selected pharmacy to added in options.
      setOptions((prev) =>
        prev.map((option) => {
          // To keep the selected pharmacy highlighted
          if (option.id === v?.id) {
            return {
              ...option,
              selected: true,
            };
          }

          // To remove the previous selected pharmacy
          if (selectedPharmacy?.dose_spot_id) {
            if (option.id === selectedPharmacy?.dose_spot_id) {
              return {
                ...option,
                selected: false,
              };
            }
          }
          return option;
        }),
      );

      //set selected pharmacy for add button
      setSelectedPharmacy({
        pharmacy_name: v?.label,
        address: v?.value,
        phone: v?.phone,
        patient_id: patientId,
        dose_spot_id: v?.id,
      });
    }
  };

  const onDeletePharmacy = (pharmacy) => {
    if (setSelectedPharmacyValue) {
      if (pharmacy.dose_spot_id == selectedPharmacyValue?.dose_spot_id) {
        setSelectedPharmacyValue({});
      }
    }
    setSkeletonLoaderCount((prev) => prev - 1);
    deletePharmacyMutation.mutate(pharmacy?.id);
    setOptions((prev) =>
      prev.map((option) => {
        if (option.id === Number(pharmacy?.dose_spot_id)) {
          return {
            ...option,
            disabled: false,
          };
        }
        return option;
      }),
    );
  };

  function addSelectedPharmacy() {
    setSelectedPharmacyValue && setSelectedPharmacyValue(selectedPharmacy);
    increaseSkeletonLoaderCount();
    addPharmacyMutation.mutateAsync(selectedPharmacy);
  }

  useEffect(() => {
    setAddedPharmacies(pharmacyList);
  }, [pharmacyList]);

  useEffect(() => {
    setSkeletonLoaderCount(addedPharmacies?.length);
  }, [addedPharmacies]);

  useEffect(() => {
    /**
     * Update the options with pharmacies from the current page.
     * If there are no pages, set options to an empty array.
     */
    const searchedPharmaciesOptions = data?.pages?.length
      ? data.pages[data.pages.length - 1]?.results || []
      : [];
    setOptions((prevState) => {
      if (addedPharmacies?.length) {
        const updatedOptions = searchedPharmaciesOptions.map((option) => {
          const isSelected = addedPharmacies?.some(
            (pharmacy) => Number(pharmacy?.dose_spot_id) === option?.id,
          );
          return {
            ...option,
            disabled: isSelected,
          };
        });
        return [...prevState, ...updatedOptions];
      } else {
        return [...prevState, ...searchedPharmaciesOptions];
      }
    });
  }, [data]);

  useEffect(() => {
    if (zipCode === null) setZipCode(getSpecificPatientData?.zipcode || "");
  }, [getSpecificPatientData]);

  let isLoading =
    addPharmacyMutation.isPending ||
    deletePharmacyMutation.isPending ||
    isPharmaciesFetching ||
    isPharmaciesLoading;

  return (
    <Box>
      <Box padding={2}>
        <Box sx={{ float: "right", marginBottom: 1 }}>
          <Box sx={{ marginLeft: 1 }}>
            <Text variant="bodyS" fontWeight={600}>
              Patient Zip Code:
            </Text>{" "}
            <Text variant="bodyS" fontWeight={400}>
              {getSpecificPatientData?.zipcode ?? ""}
            </Text>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <SelectField
            cols={10}
            onSearch={handleSearch}
            onChange={onPharmacySelect}
            options={options}
            disabled={isLoading}
            freeSolo={true}
            // Stop filtration of options.
            enableFiltering={false}
            loading={isSearchedPharmaciesLoading}
            placeholder="Search pharmacy"
            showMoreInLabel={{ show: true, value: "value" }}
            // Details for the infinite scroll in dropdown
            infiniteScrollDetails={{
              hasMoreToFetch: searchedText?.length > 2 ? !!hasNextPage : null,
              fetchMore: () => fetchNextPage(),
            }}
          />
          <InputField
            sm={2}
            md={2}
            lg={2}
            xl={2}
            disabled={isLoading}
            validators={[Required]}
            placeholder={"Zip Code"}
            onChange={(e) => changeZipCode(e)}
            value={zipCode}
          />
        </Grid>
        <Box paddingY={2} paddingX={2}>
          <SkeletonWrapper
            condition={!isLoading}
            // this count will be dynamic
            multipleCount={skeletonLoaderCount}
            props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Text fontWeight={600} variant="bodyS">
                  Pharmacy Name
                </Text>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Text fontWeight={600} variant="bodyS">
                  Address
                </Text>
              </Grid>
            </Grid>
            <Box sx={{ maxHeight: "300px", overflowY: "auto", marginTop: 2 }}>
              {addedPharmacies?.length ? (
                addedPharmacies?.map((pharmacy, index) => (
                  <Grid
                    container
                    spacing={2}
                    paddingY={1.5}
                    key={index}
                    width={"99%"}
                    borderBottom={" 1px solid #ccc"}>
                    <Grid item xs={12} sm={3}>
                      <Text variant="bodyS">{pharmacy?.pharmacy_name}</Text>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Text variant="bodyS">{pharmacy?.address}</Text>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <span
                          onClick={() => onDeletePharmacy(pharmacy)}
                          style={{ cursor: "pointer" }}>
                          x
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid
                  container
                  spacing={2}
                  paddingY={1.5}
                  borderBottom={" 1px solid #ccc"}>
                  <Grid item xs={12} sm={12} textAlign={"center"}>
                    <Text center={true} variant="bodyS">
                      No Pharmacies Found
                    </Text>
                  </Grid>
                </Grid>
              )}
            </Box>
            {apiError.length > 0 && (
              <Alert
                sx={{ marginTop: "5px" }}
                severity="error"
                onClose={() => setApiError("")}>
                {" "}
                {apiError}{" "}
              </Alert>
            )}
          </SkeletonWrapper>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}>
        <Button
          text={"Close"}
          variant="outlined"
          color="tertiary"
          onClick={() => {
            setIsOpenModal && setIsOpenModal(false);
          }}
          sx={{ marginRight: "10px", width: "150px" }}
        />
        <Button
          text={"Add"}
          isLoading={
            addPharmacyMutation.isPending ||
            deletePharmacyMutation.isPending ||
            isPharmaciesFetching
          }
          disabled={selectedPharmacy === null || isLoading}
          sx={{ marginRight: "10px", width: "150px" }}
          onClick={() => addSelectedPharmacy()}
        />
      </Box>
    </Box>
  );
};

export default memo(PharmacyModal);
