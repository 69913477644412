import { ThemeProvider } from "@mui/material";
import ErrorHandler from "components/common/ErrorHandler";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import "styles/index.css";
import { createCustomTheme } from "styles/theming/theme";
import App from "./App";
import { persistor, store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createCustomTheme();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          transition={Slide}
          pauseOnHover
        />
        <ErrorHandler />
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
);
