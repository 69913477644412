import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React from "react";

class ErrorHandler extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <Alert severity="error" sx={{ minWidth: "300px" }}>
            <AlertTitle>Error</AlertTitle>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Something went wrong. Try refreshing the browser!
            </Typography>
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorHandler;
