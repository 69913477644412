import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchAllMedicalEncounters = async (patientId) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/`;
  const response = await apiGet(url);

  let next = response.data?.next;

  while (next) {
    const nextResponse = await apiGet(next);
    response.data?.results.push(...nextResponse.data?.results);
    next = nextResponse.data?.next;
  }

  return response.data;
};

export const fetchMedicalEncounter = async (patientId, id) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/${id}/`;
  const response = await apiGet(url);
  return response.data;
};

export const addMedicalEncounter = async (patientId, data) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateMedicalEncounter = async (patientId, id, data) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deleteMedicalEncounter = async (patientId, id) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};
