import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  changePasswordMessage: "",
  changePasswordSuccess: null,
  isChangePasswordLoading: false,
};
const name = "changePassword";

export const changePasswordSlice = createSlice({
  name,
  initialState,
  reducers: {
    changeUserPassword: (state, action) => {
      state.isChangePasswordLoading = true;
    },
    changeUserPasswordSuccess: (state, action) => {
      state.changePasswordSuccess = true;
      state.isChangePasswordLoading = false;
      state.changePasswordMessage = action.payload?.detail;
    },
    changeUserPasswordFail: (state, action) => {
      state.changePasswordSuccess = false;
      state.isChangePasswordLoading = false;
      state.changePasswordMessage = action.payload?.message?.detail;
    },
    clearChangePassword: (state, action) => {
      state.changePasswordMessage = "";
      state.changePasswordSuccess = null;
      state.isChangePasswordLoading = false;
    },
  },
});

export const { changeUserPassword, clearChangePassword } =
  changePasswordSlice.actions;

export default changePasswordSlice.reducer;

export const changePasswordUser = (data) => ({
  payload: {
    apiName: name,
    data,
    method: "post",
    url: `/api/users/update_password/`,
  },
  type: "changeUserPassword",
});
