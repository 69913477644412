import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSendMessageLoading: false,
  sendMessageSuccess: null,
};

const name = "sendMessage";

export const sendMessageSlice = createSlice({
  name,
  initialState,
  reducers: {
    sendMessage: (state, action) => {
      state.isSendMessageLoading = true;
    },
    sendMessageSuccess: (state, action) => {
      state.isSendMessageLoading = false;
      state.sendMessageSuccess = true;
    },
    sendMessageFail: (state, action) => {
      state.isSendMessageLoading = false;
      state.sendMessageSuccess = false;
    },
    clearSendMessageData: (state, action) => {
      state.isSendMessageLoading = false;
      state.sendMessageSuccess = null;
    },
  },
});

export const { sendMessage, clearSendMessageData } = sendMessageSlice.actions;

export default sendMessageSlice.reducer;

export const sendMessageToEmail = (data) => ({
  payload: {
    apiName: name,
    data,
    method: "post",
    url: `/api/message/`,
  },
  type: "sendMessage",
});
