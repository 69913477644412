import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchPayers = async (patientId) => {
  const url = `/api/patients/${patientId}/payers/`;
  const response = await apiGet(url);
  return response.data;
};

export const addPayer = async (patientId, data) => {
  const url = `/api/patients/${patientId}/payers/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updatePayer = async (patientId, id, data) => {
  const url = `/api/patients/${patientId}/payers/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deletePayer = async (patientId, id) => {
  const url = `/api/patients/${patientId}/payers/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};
