import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchPluginIntegrations,
  fetchCredentials,
  addCredentials,
} from "apiClients/emrCredentials";
import {
  emrCredentialWrapperStyles,
  saveCredentialBoxStyling,
  saveCredentialTextStyle,
} from "./emrAuthStyle";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import CredentialItem from "./editCredentials";
import { emrCredentialsSchema } from "validations/Settings/emrCredentials.schema";
import { useFormik } from "formik";

const EHRAuthentication = () => {
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { data: pluginsOptions, isLoading: isPluginsLoading } = useQuery({
    queryKey: ["integration"],
    queryFn: fetchPluginIntegrations,
  });

  const {
    data: emrCredentialsData,
    isFetching,
    isLoading: isEmrCredentialsLoading,
  } = useQuery({
    queryKey: ["credentials"],
    queryFn: fetchCredentials,
  });

  const formik = useFormik({
    initialValues: {
      plugin_id: "",
      username: "",
      password: "",
    },
    validationSchema: emrCredentialsSchema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const { isPending, mutate } = useMutation({
    mutationFn: async (data) => {
      await addCredentials(data);
    },
    onSuccess: () => {
      //remove error message and reset form once form is submitted
      setError("");
      formik.resetForm();
      queryClient.invalidateQueries({ queryKey: ["credentials"] });
    },
    onError: (error) => {
      // Assuming the error contains a message about invalid credentials
      setError(error?.response?.data?.message);
    },
  });

  useEffect(() => {
    const alreadyExists = emrCredentialsData?.find(
      (creds) => creds.plugin_id === formik.values?.plugin_id,
    );
    setError(alreadyExists ? "EMR Credential already added" : "");
  }, [formik.values?.plugin_id, emrCredentialsData?.length]);

  return (
    <Box sx={emrCredentialWrapperStyles}>
      <Text variant="h1">EMR Credentials</Text>
      <Text variant="h2" sx={{ marginBottom: "10px" }}>
        Add New Credentials
      </Text>
      <Grid container spacing={2} mb={1}>
        <SelectField
          name={"plugin_id"}
          formik={formik}
          required
          options={pluginsOptions}
          isLoading={isPluginsLoading}
          inputLabel="EMR Name"
          cols={4}
          md={4}
        />
        <InputField
          formik={formik}
          name={"username"}
          required
          inputLabel="User Name"
          lg={4}
          md={4}
          sm={6}
        />
        <InputField
          formik={formik}
          name={"password"}
          required
          inputLabel="Password"
          lg={4}
          md={4}
          sm={6}
          type="password"
        />
      </Grid>
      <Box>
        {error && (
          <Text marginY={1} variant="body2" color="red">
            {error}
          </Text>
        )}
      </Box>
      <Button
        isLoading={isPending}
        disabled={error === "EMR Credential already added"}
        text="Add Credentials"
        onClick={() => formik.submitForm()}
      />
      <Box>
        <Box style={saveCredentialBoxStyling}>
          <Box sx={saveCredentialTextStyle}>
            <Text variant="h2">Saved Credentials</Text>
          </Box>
          <SkeletonWrapper
            condition={!(isEmrCredentialsLoading || isFetching)}
            multipleCount={3}
            props={[SkeletonPropsStyleGenerator("98%", "40px", "150px")]}>
            {emrCredentialsData?.length > 0 ? (
              <Grid container spacing={2}>
                {emrCredentialsData?.map((credential, i) => (
                  <CredentialItem key={i} credential={credential} />
                ))}
              </Grid>
            ) : (
              <Text>No saved credentials</Text>
            )}
          </SkeletonWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default EHRAuthentication;
