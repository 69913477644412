import { apiGet, apiPost } from "./base";

export const fetchFeedbacksForPatientEncounter = async (
  patientId,
  encounterId,
  options = {},
) => {
  const { signal } = options; // Extract signal from options
  const url = `/api/feedback/?patient_id=${patientId}&encounter_id=${encounterId}`;
  const response = await apiGet(url, signal);

  let next = response.data?.next;

  while (next) {
    if (signal?.aborted) {
      break;
    }
    const nextResponse = await apiGet(next, signal);
    response.data?.results.push(...nextResponse.data?.results);
    next = nextResponse.data?.next;
  }

  return response.data?.results;
};

export const addFeedback = async (data) => {
  const url = `/api/feedback/`;
  const response = await apiPost(url, data);
  return response.data;
};
