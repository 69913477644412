import { Box, CircularProgress } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useState } from "react";
import { flexColumnCenterCenter } from "styles";
import { useParams } from "react-router";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import MedicationDetail from "./MedicationDetail";
import { useQuery } from "@tanstack/react-query";
import { fetchAllMedications } from "apiClients/medications";

const getActiveAndInActiveMedications = (medications) => {
  const active =
    medications?.filter(
      (s) =>
        s?.end_date === null ||
        (s?.end_date === null && s?.start_date === null),
    ) ?? [];

  const inactive = medications?.filter((s) => s?.end_date !== null) ?? [];

  return { active, inactive };
};

const Medications = () => {
  let { patientId } = useParams();
  const [activeMedications, setActiveMedications] = useState([]);
  const [inActiveMedications, setInActiveMedications] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ["medications", patientId],
    queryFn: async () => await fetchAllMedications(patientId),
  });

  useEffect(() => {
    const { active, inactive } = getActiveAndInActiveMedications(data?.results);
    setActiveMedications(active);
    setInActiveMedications(inactive);
  }, [data]);

  return (
    <Box
      sx={{ padding: "15px !important", maxHeight: "96vh", overflowY: "auto" }}>
      <Box sx={{ marginTop: "5px" }}>
        <Text id="scd-medications-data" variant="bodyM" fontWeight="600">
          Active
        </Text>
      </Box>
      <Box data-testid={`scd-medication-main-box-id`}>
        <SkeletonWrapper
          id={"skeleton-medication-strip"}
          condition={
            isLoading &&
            ![...(inActiveMedications || []), ...(activeMedications || [])]
              ?.length
              ? false
              : true
          }
          multipleCount={3}
          props={[SkeletonPropsStyleGenerator("43vmax", "60px", "150px")]}>
          <MedicationDetail
            activeMedications={activeMedications}
            inActiveMedications={inActiveMedications}
          />
        </SkeletonWrapper>
        {isLoading &&
        [...(inActiveMedications || []), ...(activeMedications || [])].length >
          0 ? (
          <Box sx={{ marginTop: "5px", ...flexColumnCenterCenter }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default memo(Medications);
