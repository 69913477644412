const getGroupWiseUsers = (groups = [], users = []) => {
  let comnineData = [];
  groups.forEach((g, index) => {
    // Find users that belong to the current group
    const groupUsers = users.filter((u) => u?.groups?.[0] === g.value);

    // If there are users for the group, push the formatted data
    if (groupUsers.length > 0) {
      comnineData.push({
        id: g.value,
        name: g.label,
        data: groupUsers.map((u) => ({
          id: u.id,
          username: u.username,
          email: u.email,
          groups: u.groups,
          firstName: u.first_name,
          lastName: u.last_name,
          designation: u.designation,
          photo: u.photo,
          title: u.title,
          accounts: u.accounts,
          active_account_id: u.active_account_id,
        })),
      });
    }
  });

  return comnineData;
};

export { getGroupWiseUsers };
