import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import ImageDropzone from "components/common/FileUploader";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import moment from "moment/moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearAdminStatuses,
  getAdminInfo,
  updateAdminInfo,
} from "redux/features/Admin/AdminSlice";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { setActiveLocation } from "redux/features/Locations/locationsSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import { titleOptions } from "utils/profile/utils";
import * as Yup from "yup";

const widthForFields = {
  width: { lg: "45%", sm: "100%" },
  marginRight: { lg: "10px" },
};

const profileInformationData = [
  {
    element: "input",
    type: "text",
    sx: { marginRight: "100px", ...widthForFields },
    label: "First Name",
    name: "first_name",
    placeHolder: "First Name",
  },
  {
    element: "input",
    type: "text",
    sx: { ...widthForFields },
    label: "Last Name",
    name: "last_name",
    placeHolder: "Last Name",
  },
  {
    element: "input",
    type: "date",
    sx: { marginTop: "5px", ...widthForFields },
    label: "DOB",
    name: "birthdate",
    max: new Date().toISOString().split("T")[0],
    placeHolder: "1999/01/01",
  },
  {
    element: "input",
    type: "text",
    sx: { marginTop: "5px", ...widthForFields },
    label: "National Provider ID",
    name: "national_provider_identifier",
    placeHolder: "National Provider ID",
  },
  {
    element: "input",
    type: "text",
    sx: { marginTop: "5px", ...widthForFields },
    label: "Phone Number",
    name: "phone_number",
    placeHolder: "+1 (123) 123-1234",
  },
];

const phoneNumberRegex = new RegExp(
  /^(\+)?(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/,
);

const ProfileSettings = () => {
  const dispatch = useDispatch();

  const { userId } = useSelector((state) => state.auth);
  const { account_id } = useSelector((state) => state.auth);
  const { isAdminUpdateLoading, adminData, adminUpdateSuccess } = useSelector(
    (state) => state.admin,
  );
  const { locations, isLocationsLoading, activeLocation } = useSelector(
    (state) => state.locations,
  );

  useEffect(() => {
    userId && dispatch(getAdminInfo(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (adminUpdateSuccess) {
      dispatch(getAdminInfo(userId));
      dispatch(getAccountID());
      toast.success("Profile Updated");
    }
  }, [dispatch, userId, adminUpdateSuccess]);

  const formik = useFormik({
    initialValues: {
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.title
        : titleOptions[0]?.title,
      first_name: adminData?.first_name ?? "",
      last_name: adminData?.last_name ?? "",
      username: adminData?.username ?? "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format("YYYY-MM-DD")
        : "",
      national_provider_identifier:
        adminData?.national_provider_identifier ?? "",
      email: adminData?.email ?? "",
      phone_number: adminData?.phone_number ?? "",
      photo: adminData?.photo ?? "",
    },
    validationSchema: Yup.object({
      birthdate: Yup.string().required("Birth date is required"),
      title: Yup.string().required("Title is required"),
      phone_number: Yup.string().matches(phoneNumberRegex, {
        message: "Please enter a valid phone number",
      }),
      national_provider_identifier: Yup.string().max(
        10,
        "Ensure this field has no more than 10 characters",
      ),
    }),
    onSubmit: (data) => {
      let formData = new FormData();
      formData.append("title", data?.title?.value ?? data?.title);
      formData.append("first_name", data?.first_name);
      formData.append("last_name", data?.last_name);
      // currently removed groups and accounts from the form data just to test the api (suggested by backend team)
      // might need to add them back later
      // formData.append("groups", adminData?.groups);
      // formData.append("accounts", adminData?.accounts);
      formData.append("username", data?.username);
      formData.append("birthdate", data?.birthdate);
      formData.append(
        "national_provider_identifier",
        data?.national_provider_identifier,
      );
      formData.append("email", data?.email);
      formData.append("phone_number", data?.phone_number);
      typeof data?.photo !== "string" && formData.append("photo", data?.photo);
      dispatch(updateAdminInfo(userId, formData));
    },
  });

  useEffect(() => {
    formik.setValues({
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.label
        : titleOptions[0]?.label,
      first_name: adminData?.first_name ?? "",
      last_name: adminData?.last_name ?? "",
      username: adminData?.username ?? "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format("YYYY-MM-DD")
        : "",
      national_provider_identifier:
        adminData?.national_provider_identifier ?? "",
      email: adminData?.email ?? "",
      phone_number: adminData?.phone_number ?? "",
      photo: adminData?.photo ?? "",
    });
  }, [adminData]);

  useEffect(() => {
    return () => dispatch(clearAdminStatuses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  function setLocation(_, v) {
    // setting active location id is store.
    dispatch(setActiveLocation(v));
  }

  return (
    <>
      <Box
        className="title-box"
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          padding: "4px 15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Text marginY={2} id={"profile-heading"} variant="h1" fontWeight="700">
          {" "}
          Profile Settings{" "}
        </Text>
        <Grid sx={{ width: "30%" }} container>
          <SelectField
            required
            cols={12}
            name={"Locations"}
            options={locations}
            disableClearable={true}
            defaultValue={activeLocation}
            loading={isLocationsLoading}
            onChange={setLocation}
            inputLabel={"Locations"}
          />
        </Grid>
      </Box>
      <>
        {adminData && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ padding: "20px", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    height: "auto",
                  }}>
                  <Box sx={{ width: "45%" }}>
                    <SelectField
                      key={adminData?.title}
                      name={"title"}
                      formik={formik}
                      options={titleOptions}
                      defaultValue={
                        adminData?.title
                          ? titleOptions?.find(
                              ({ value }) => value === adminData?.title,
                            )
                          : titleOptions[0]
                      }
                      label={"Title"}
                      cols={12}
                      placeholder={"Select Title"}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", width: "50%", marginLeft: "20px" }}>
                    <ImageDropzone formik={formik} name="photo" />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    paddingLeft: "0px",
                    gap: "10px",
                  }}>
                  {profileInformationData.map((item, i) =>
                    item?.element === "input" ? (
                      <Box key={i} sx={item?.sx}>
                        <InputField
                          {...(item.label === "DOB" && { max: item.max })}
                          {...(item.type === "email" && { disabled: true })}
                          name={item?.name}
                          formik={formik}
                          type={item?.type}
                          label={item?.label}
                          labelVariant="bodyS"
                          fontWeight="400"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          placeholder={item?.placeHolder}
                        />
                      </Box>
                    ) : null,
                  )}
                  <Grid item>
                    <Text variant="h4" sx={{ marginTop: "10px" }}>
                      Email :
                    </Text>
                    <Text variant="h4" sx={{ marginTop: "15px" }}>
                      {adminData?.email ?? ""}
                    </Text>
                  </Grid>
                </Box>

                <Button
                  sx={{ marginTop: "15px" }}
                  isLoading={isAdminUpdateLoading}
                  text="Update Profile"
                  onClick={formik.handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

export default ProfileSettings;
