import { createSlice } from "@reduxjs/toolkit";

export const initialVitalsState = {
  vitalsIsLoading: false,
  vitalsSuccess: null,
  vitalsData: [],
};

const name = "vitals";

export const vitalsSlice = createSlice({
  name,
  initialState: initialVitalsState,
  reducers: {
    getVitals: (state, action) => {
      state.vitalsIsLoading = true;
    },
    getVitalsSuccess: (state, action) => {
      state.vitalsIsLoading = false;
      state.vitalsSuccess = true;
      state.vitalsData = action.payload?.results;
    },
    getVitalsFail: (state, action) => {
      state.vitalsIsLoading = false;
      state.vitalsSuccess = false;
      state.vitalsData = [];
    },
    clearVitalsData: (state, action) => {
      state.vitalsIsLoading = false;
      state.vitalsSuccess = null;
      state.vitalsData = [];
    },
  },
});

export const { getVitals, clearVitalsData } = vitalsSlice.actions;

export default vitalsSlice.reducer;

export const getVitalsList = (patientId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/vitals/`,
  },
  type: "getVitals",
});
