import React from "react";

const DeleteIcon = ({ height = "14", width = "15", fill = "none" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.15973 6.30371V10.282"
        stroke="#555555"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.15985 6.30371V10.282"
        stroke="#555555"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1599 3.65234H7.65985H3.15985V10.9459C3.15985 12.0445 4.16718 12.9351 5.40985 12.9351H9.90985C11.1525 12.9351 12.1599 12.0445 12.1599 10.9459V3.65234Z"
        stroke="#555555"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4419 3.65186H1.44189"
        stroke="#555555"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.69147 2.3261C9.69147 1.59372 9.01989 1 8.19147 1H6.69147C5.86302 1 5.19147 1.59372 5.19147 2.3261V3.65221H9.69147V2.3261Z"
        stroke="#555555"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
