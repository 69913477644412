import * as Yup from "yup";

export const OrderMedicationsSchema = Yup.object({
  patient_id: Yup.number().nullable().required("Patient is required"),
  refills: Yup.number()
    .typeError("Refills must be a number")
    .required("Refills is required")
    .min(0, "Refills must be at least 0")
    .max(500, "Refills must be at most 500"),
  dispensable_drug_id: Yup.string().required("Medication is required"),
  drug_name: Yup.string().required("Drug Name is required"),
  quantity: Yup.number()
    .typeError("Quantity must be a number")
    .required("Quantity is required")
    .min(1, "Quantity must be at least 1")
    .max(500, "Quantity must be at most 500"),
  encounter_id: Yup.number().nullable().required("Encounter is required"),
  pharmacy_id: Yup.number().nullable().required("Pharmacy is required"),
});
