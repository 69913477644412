import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchAllergies = async (patientId) => {
  const url = `/api/patients/${patientId}/allergies/`;
  const response = await apiGet(url);
  return response.data;
};

export const deleteAllergy = async (patientId, id) => {
  const url = `/api/patients/${patientId}/allergies/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};

export const updateAllergy = async (patientId, id, data) => {
  const url = `/api/patients/${patientId}/allergies/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const addAllergy = async (patientId, data) => {
  const url = `/api/patients/${patientId}/allergies/`;
  const response = await apiPost(url, data);
  return response.data;
};
