import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import MainLayoutContext from "contexts/MainLayoutContext";
import ThemeContext from "contexts/ThemeContext";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes/Router";
import { createCustomTheme } from "styles/theming/theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

function App() {
  const theme = createCustomTheme();
  const [title, setTitle] = useState("");
  const [titleBody, setTitleBody] = useState("");
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={theme}>
        <MainLayoutContext.Provider
          value={{
            title: title,
            setTitle: setTitle,
            titleBody: titleBody,
            setTitleBody: setTitleBody,
          }}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <CssBaseline />
              <Router theme={theme} />
            </BrowserRouter>
          </QueryClientProvider>
        </MainLayoutContext.Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}

export default App;
