export let graphStaticApiFullFormValue = {
  Temp: "temperature",
  BPS: "systolic",
  BPD: "diastolic",
  HR: "pulse",
  RR: "respiration_rate",
  WT: "weight",
  Spo2: "spo2",
};

export let graphStaticColors = {
  BPS: "#8e44ad",
  BPD: "#e74c3c",
  HR: "#e67e22",
  RR: "#1abc9c",
  WT: "#34495e",
  Temp: "#0652DD",
  Spo2: "#FDA7DF",
};
