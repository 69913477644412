import { createSlice } from "@reduxjs/toolkit";

export const name = "historyNotes";

export const initialNotesHistoryStateValue = {
  isNotesHistoryLoading: false,
  results: [],
};

export const notesHistorySlice = createSlice({
  name,
  initialState: initialNotesHistoryStateValue,
  reducers: {
    notesHistory: (state, action) => {
      state.isNotesHistoryLoading = true;
    },
    notesHistorySuccess: (state, action) => {
      state.isNotesHistoryLoading = false;
      state.results = action.payload;
    },
    notesHistoryFail: (state, action) => {
      state.isNotesHistoryLoading = false;
      state.results = [];
    },
    clearNotesHistoryMessage: (state, action) => {
      state.message = "";
      state.isNotesHistoryLoading = false;
      state.results = [];
    },
  },
});

export const {
  notesHistorySuccess,
  notesHistoryFail,
  clearNotesHistoryMessage,
} = notesHistorySlice.actions;

export default notesHistorySlice.reducer;

export const getNotesHistory = (patienId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/notes-history/${patienId}/`,
  },
  type: "notesHistory",
});
