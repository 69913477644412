import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import GridOnIcon from "@mui/icons-material/GridOn";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list";
import { INSERT_TABLE_COMMAND, TableNode } from "@lexical/table";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
const LowPriority = 1;

function Divider() {
  return <div className="divider" />;
}

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [showTableErrorTooltip, setShowTableErrorTooltip] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
    }
  }, []);

  //  to check whether cursor is inside table or not.
  function isSelectionInsideTable() {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      let node = selection.anchor.getNode();
      while (node) {
        if (node instanceof TableNode) {
          return true;
        }
        node = node.getParent();
      }
    }
    return false;
  }

  const createTable = () => {
    editor.read(() => {
      if (!isSelectionInsideTable()) {
        editor.update(() => {
          editor.dispatchCommand(INSERT_TABLE_COMMAND, {
            columns: String(2),
            includeHeaders: true,
            rows: String(2),
          });
        });
      } else {
        setShowTableErrorTooltip(true);
        setTimeout(() => {
          setShowTableErrorTooltip(false);
        }, 2000);
      }
    });
  };

  const handleCopy = () => {
    // Access the editor state and convert to plain text
    const plainText = editor.getEditorState().read(() => {
      return $getRoot().getTextContent();
    });
    navigator.clipboard.writeText(plainText).then(() => {
      setShowCopyTooltip(true);
      setTimeout(() => {
        setShowCopyTooltip(false);
      }, 2000);
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, $updateToolbar]);

  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Undo">
        <UndoIcon className="format" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className="toolbar-item"
        aria-label="Redo">
        <i className="format redo" />

        <RedoIcon className="format" />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"toolbar-item spaced " + (isBold ? "active" : "")}
        aria-label="Format Bold">
        <FormatBoldIcon className="format" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
        aria-label="Format Italics">
        <FormatItalicIcon className="format" />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        }}
        className={"toolbar-item spaced "}
        aria-label="Format Italics">
        <FormatListBulletedIcon className="format" />
      </button>
      <CustomTooltip
        tooltipText="Cannot create table inside another table"
        arrow
        placement="top"
        clickToOpen={{
          isOpenOnClickEnabled: true,
          isOpen: showTableErrorTooltip,
        }}>
        <button
          onClick={() => createTable()}
          className={"toolbar-item spaced "}
          aria-label="Format Italics">
          <GridOnIcon className="format" />
        </button>
      </CustomTooltip>
      <Divider />

      <CustomTooltip
        tooltipText="Copied"
        arrow
        placement="top"
        clickToOpen={{
          isOpenOnClickEnabled: true,
          isOpen: showCopyTooltip,
        }}>
        <button
          onClick={() => {
            handleCopy();
          }}
          className="toolbar-item spaced"
          aria-label="Undo">
          <CopyAllIcon className="format" />
        </button>
      </CustomTooltip>
    </div>
  );
};
export default ToolbarPlugin;
