import { createSlice } from "@reduxjs/toolkit";

export const initialLabProvidersState = {
  labProvidersData: [],
  labProvidersDataOptions: [],
  labProvidersSuccess: null,
  labProvidersIsLoading: false,
};

const name = "labProviders";

export const labProvidersSlice = createSlice({
  name,
  initialState: initialLabProvidersState,
  reducers: {
    getLabProviders: (state, action) => {
      state.labProvidersIsLoading = true;
    },
    getLabProvidersSuccess: (state, action) => {
      state.labProvidersSuccess = true;
      state.labProvidersIsLoading = false;
      state.labProvidersData = action.payload.results;
      state.labProvidersDataOptions = action.payload?.results?.map((s) => ({
        label: s?.name,
        value: s?.id,
      }));
    },
    getLabProvidersFail: (state, action) => {
      state.labProvidersSuccess = false;
      state.labProvidersData = [];
      state.labProvidersIsLoading = false;
      state.labProvidersDataOptions = [];
    },
    clearLabProvidersData: (state, action) => {
      state.labProvidersSuccess = null;
      state.labProvidersData = [];
      state.labProvidersIsLoading = false;
      state.labProvidersDataOptions = [];
    },
  },
});

export const { clearLabProvidersData } = labProvidersSlice.actions;

export default labProvidersSlice.reducer;

export const getLabProvidersList = () => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/labproviders/`,
  },
  type: "getLabProviders",
});
