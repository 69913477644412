import { Chip } from "@mui/material";

const DraggableChip = ({
  className = "",
  label = "",
  onDelete,
  onClick = () => {},
}) => {
  return (
    <Chip
      sx={{ maxWidth: "240px" }}
      onClick={onClick}
      className={className}
      label={label}
      size="small"
      variant="outlined"
      {...(onDelete ? { onDelete: onDelete } : {})}
    />
  );
};

export default DraggableChip;
