export const sortWeights = {
  "eGFR non-Afr. American": 0,
  "eGFR African American": 1,
  "Urea Nitrogen (BUN)": 2,
  "BUN/Creatinine Ratio": 3,
  Creatinine: 4,
  Sodium: 5,
  Potassium: 6,
  Hgb: 7,
  Hct: 8,
  PLT: 9,
  HCO3: 10,
};

export const getGroupedFormattedLabs = (data) => {
  let count = 0;
  const labGroups = {};

  data?.lab_tests?.forEach((labTest) => {
    // initialize the lab group
    labGroups[labTest.name] = [];
    count = labTest?.lab_observations?.length - 1;
    labTest?.lab_observations.forEach((details) => {
      const value = details?.value;
      //added this check to avoid the values which are empty
      if (value?.[0] !== "") {
        let labValue = `${details?.value}${details?.unit ? ` ${details.unit}` : ""}`;
        let labRange = `${details?.reference_range}${details?.unit ? ` ${details.unit}` : ""}`;

        let lab = {
          val: labValue,
          range: labRange,
          code: details?.name,
          at_risk: details?.in_range,
        };

        labGroups[labTest.name].push(lab);
      }
    });
  });

  return {
    count,
    labGroups,
  };
};
