import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchPluginIntegrations = async () => {
  const url = `/api/integration_plugins/`;
  const response = await apiGet(url);
  return response.data?.results?.reduce(
    (acc, { name, id, user_auth_required }) => {
      if (user_auth_required) {
        acc.push({ label: name, value: id });
      }
      return acc;
    },
    [],
  );
};

export const fetchCredentials = async () => {
  const url = `api/integration_user_credentials/`;
  const response = await apiGet(url);
  return response.data?.results;
};

export const addCredentials = async (data) => {
  const url = `api/integration_user_credentials/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateCredentials = async (id, data) => {
  const url = `api/integration_user_credentials/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deleteCredentials = async (id) => {
  const url = `api/integration_user_credentials/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};
