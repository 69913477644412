import { Divider, Grid, useTheme } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { SubFormTitle } from "../common/SubFormTitle";
import { useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { SubForm } from "../common/SubForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addPayer,
  deletePayer,
  fetchPayers,
  updatePayer,
} from "apiClients/payer";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Button from "components/common/Button/Button";
import { Required } from "validations/validators";

const secondaryInsuranceInputs = [
  {
    label: "Name",
    key: "payer_name",
    cols: 4,
    validators: [Required],
  },
  {
    label: "Policy Number",
    key: "policy_number",
    cols: 4,
    validators: [Required],
  },
  {
    label: "Group Number",
    key: "group_number",
    cols: 4,
    validators: [Required],
  },
];

export const InsuranceReview = ({ patientId }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [values, setValues] = useState({});
  const [addingPrimary, setAddingPrimary] = useState(false);
  const { registerForm } = useContext(IntakeFormContext);

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medication.
          return deletePayer(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medication since it is dirty (changed).
          return updatePayer(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medication and we need to add it.
        return addPayer(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["payers", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["payers", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["payers", patientId],
    queryFn: async () => await fetchPayers(patientId),
  });

  const mapToFormFn = (results) => {
    let primaryInsurance = {};
    let secondaries = [];
    for (let result of results) {
      if (result.primary_insurance) {
        primaryInsurance = result;
      } else {
        secondaries.push(result);
      }
    }

    return {
      ...primaryInsurance,
      secondaries,
    };
  };

  const mapToApiFn = (values) => {
    const { secondaries, ...rest } = values;
    const keysToCheck = [
      "payer_name",
      "policy_number",
      "group_number",
      "guarantor_social_security_number",
      "guarantor_birthdate",
      "guarantor_address",
      "guarantor_city",
      "guarantor_state",
      "guarantor_zipcode",
      "guarantor_phone_number",
      "guarantor_first_name",
      "guarantor_last_name",
    ];
    const isPrimaryEmpty = Object.entries(rest).every(([key, value]) => {
      if (keysToCheck.includes(key)) {
        return value === "";
      }

      return true;
    });
    if (isPrimaryEmpty) {
      return [];
    }
    const primaryInsurance = rest;
    const secondaryInsurances = secondaries;

    const all = [primaryInsurance, ...secondaryInsurances];
    return all;
  };

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      return mutation.mutateAsync(dirtyValue);
    });

    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (data) {
      const mapped = mapToFormFn(data.results);
      // Insurance has a special case where we need to set the initial values without mapping because we will need to map it back to the API format via the mapper fn.
      registerForm("insurance", handleSubmit, data.results, mapToApiFn);
      setValues(mapped);
    }
  }, [data]);

  return (
    <>
      <Text variant="h1">Insurance Review</Text>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={1}>
        <SkeletonWrapper
          condition={!isLoading}
          props={[{ width: "100%", height: "40px" }]}>
          {values.payer_name || addingPrimary ? (
            <>
              <InputField
                value={values.payer_name}
                name="insurance.payer_name"
                inputLabel="Policy holder"
                cols={4}
                validators={[Required]}
              />
              <InputField
                value={values.policy_number}
                name="insurance.policy_number"
                inputLabel="Policy Number"
                cols={4}
                validators={[Required]}
              />
              <InputField
                value={values.group_number}
                name="insurance.group_number"
                inputLabel="Group Number"
                cols={4}
                validators={[Required]}
              />

              <SubFormTitle
                tag="h2"
                title="Guarantor Information"
                sx={{ marginLeft: "12px" }}
              />
              <InputField
                value={values.guarantor_first_name}
                name="insurance.guarantor_first_name"
                inputLabel="First Name"
                cols={6}
              />
              <InputField
                value={values.guarantor_last_name}
                name="insurance.guarantor_last_name"
                inputLabel="Last Name"
                cols={6}
              />
              <InputField
                value={values.guarantor_social_security_number}
                name="insurance.guarantor_social_security_number"
                inputLabel="Social Security Number"
                cols={3}
              />
              <InputField
                value={values.guarantor_birthdate}
                name="insurance.guarantor_birthdate"
                inputLabel="Birthdate"
                type="date"
                cols={3}
                validators={[Required]}
              />
              <InputField
                value={values.guarantor_address}
                name="insurance.guarantor_address"
                inputLabel="Address"
                cols={6}
              />
              <InputField
                value={values.guarantor_city}
                name="insurance.guarantor_city"
                inputLabel="City"
                cols={3}
              />
              <InputField
                value={values.guarantor_state}
                name="insurance.guarantor_state"
                inputLabel="State"
                cols={3}
              />
              <InputField
                value={values.guarantor_zipcode}
                name="insurance.guarantor_zipcode"
                inputLabel="Zipcode"
                cols={3}
              />
              <InputField
                value={values.guarantor_phone_number}
                name="insurance.guarantor_phone_number"
                inputLabel="Phone Number"
                cols={3}
              />
              <input type="hidden" name="insurance.id" value={values.id} />
              <input
                type="hidden"
                name="insurance.primary_insurance"
                value={true}
              />
            </>
          ) : (
            <>
              <SkeletonWrapper
                condition={!isLoading}
                props={[{ width: "100%", height: "40px" }]}>
                <Button
                  text="+ Add Primary Insurance"
                  variant="text"
                  sx={{ justifyContent: "flex-start" }}
                  onClick={() => setAddingPrimary(true)}
                />
              </SkeletonWrapper>
            </>
          )}
        </SkeletonWrapper>
        <Grid item lg={12}>
          <SubFormTitle
            tag="h2"
            title="Secondary Insurance"
            sx={{ marginTop: "0px", marginLeft: "10px" }}
          />
          {values.payer_name}
          <SubForm
            formKey="insurance.secondaries"
            subSections={secondaryInsuranceInputs}
            dataLoading={isLoading}
            data={values.secondaries ?? []}
            addDisabled={!values.payer_name && !addingPrimary}
          />
        </Grid>
      </Grid>
    </>
  );
};
