import { Box, Skeleton, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ListViewSchedules from "components/common/Cards/ListViewSchedules";
import CardsSkeleton from "components/common/Loaders/Skeleton/CardsSkeleton";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScheduledAppointments } from "redux/features/Patients/ScheduledAppointmentSlice";
import {
  alignItemsCenter,
  flexColumnCenterCenter,
  flexRow,
  flexRowCenterCenter,
} from "styles";
import { swiperLibraryStyling } from "styles/Dashboard/SchedulePatientsStyle";
import {
  EffectCoverflow,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "components/common/Cards/Card";
import PastVisitModal from "pages/Dashboard/PatientDetail/components/PastVisit/PastVisitModal";
import Button from "components/common/Button/Button";
import CustomModal from "components/common/Modal/CustomModal";
import { getEncounterByPatient } from "redux/features/Encounter/EncounterSlice";
import { calculateAge } from "utils/helper";

const CardsContainer = ({
  date,
  patientsData,
  isVerticalView,
  setPatientsData,
  filteredPatientsData,
  setFilteredPatientsData,
}) => {
  let theme = useTheme();
  const dispatch = useDispatch();

  const { activeLocation: { value: activeLocationId } = {} } = useSelector(
    (state) => state.locations,
  );

  const [page, setPage] = useState(1);
  const [pastVisit, setPastVisit] = useState(false);

  // isCalculating is used to show a loading skeleton during initial data load.
  // id is used to set the activeId of the swiper component.
  const [activeSlide, setActiveSlide] = useState({
    isCalculating: true,
    id: null,
  });
  const { account_id } = useSelector((state) => state.auth);
  const { count, results, scheduleAppointmentsMessage, isLoading } =
    useSelector((state) => state.patients);

  useEffect(() => {
    if (results.length > 0 && patientsData.length !== count) {
      // Only append more data if we have not yet reached the full dataset.
      setPatientsData((previous) => [...previous, ...results]);
      setFilteredPatientsData((previous) => [...previous, ...results]);
    }

    if (!isLoading) {
      setActiveSlide((prev) => ({ ...prev, isCalculating: false }));
    }
  }, [results]);

  // function for the pagination both for cards and overview modal
  function getPatientsDataOnSlide() {
    if (filteredPatientsData?.length < count) {
      setTimeout(() => {
        dispatch(
          getScheduledAppointments(
            page + 1,
            date,
            activeLocationId,
            account_id,
          ),
        );
        setPage(page + 1);
      }, 100);
    }
  }

  useEffect(() => {
    setActiveSlide({ isCalculating: true, id: null });
    setPage(1);
    setPatientsData([]);
    setFilteredPatientsData([]);
    if (account_id !== null && activeLocationId) {
      dispatch(getScheduledAppointments(1, date, activeLocationId, account_id));
    }
  }, [date, account_id, activeLocationId]);

  useEffect(() => {
    // Searches the patientData to find the next appointment. If no appointment is found before the current time then we set the last appointment as active.
    function findNextAppointment() {
      let nextAppt;
      // We can assume that the patient data is already sorted so that we only need to loop through once to find the latest appointment that is before the current time.
      // We use .some() to short circuit when we find a next appointment.
      patientsData?.some((s, i) => {
        const isInFuture =
          new Date(s.datetime).toISOString() > new Date().toISOString();
        if (s.checkin_status === "booked" && isInFuture) {
          nextAppt = i;
          return true;
        }
        return false;
      });
      if (nextAppt == null && patientsData.length !== count) {
        // If we haven't found an appointment yet and we still have more pages to load then load that data now.
        getPatientsDataOnSlide();
        return;
      }
      setActiveSlide({
        isCalculating: false,
        id: nextAppt ?? patientsData.length - 1,
      });
    }
    if (patientsData.length > 0 && activeSlide.id === null) {
      setActiveSlide({ isCalculating: true, id: null });
      findNextAppointment();
    }
  }, [patientsData]);

  function handleSlideChange(swiper) {
    setActiveSlide({ isCalculating: false, id: swiper.activeIndex });
    if (
      patientsData.length !== count &&
      swiper.activeIndex === patientsData.length - 1
    ) {
      getPatientsDataOnSlide();
    }
  }

  function handleVerticalScroll(event) {
    // To check if the user has scrolled to the bottom of the div (i.e., all content has been viewed).
    // If the difference between the scrollHeight, scrollTop, and clientHeight is less than 1, the bottom is reached.
    if (
      Math.abs(
        event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight,
      ) < 1
    ) {
      getPatientsDataOnSlide();
    }
  }

  const openPastVisit = (patientId) => {
    dispatch(getEncounterByPatient(patientId));
    setPastVisit(true);
  };

  return (
    <Box
      sx={{
        marginTop: "8px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "600px",
        ...swiperLibraryStyling(theme, filteredPatientsData?.length),
      }}>
      <CustomModal
        open={pastVisit}
        setOpen={setPastVisit}
        title={"Past Visit"}
        fullWidth
        maxWidth="md"
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button text="Close" onClick={() => setPastVisit(false)} />
          </Box>,
        ]}>
        <PastVisitModal calledFromAppts={true} setPastVisit={setPastVisit} />
      </CustomModal>
      {!isVerticalView ? (
        <>
          {activeSlide.isCalculating && activeSlide.id === null ? (
            <Swiper
              className="skeleton-swiper swiper_container"
              style={{ flex: "1" }}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={3}
              slidesPerView={"auto"}
              speed={0}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
              }}
              modules={[EffectCoverflow, Pagination]}>
              {Array(10)
                .fill("")
                .map((_, i) => (
                  <SwiperSlide key={i}>
                    <CardsSkeleton />
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            ""
          )}
          {!activeSlide.isCalculating &&
          activeSlide.id !== null &&
          patientsData.length > 0 ? (
            <Swiper
              style={{ flex: "1" }}
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={activeSlide.id}
              slidesPerView={"auto"}
              mousewheel={{ sensitivity: 0.5 }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
              }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation, Mousewheel]}
              className="swiper_container">
              {filteredPatientsData?.length ? (
                filteredPatientsData?.map((el, i) => (
                  <SwiperSlide key={i}>
                    <Card
                      openPastVisit={openPastVisit}
                      className="cards"
                      index={i}
                      dob={calculateAge(el?.patient?.birthdate)}
                      id={el?.account_id}
                      time={el?.datetime}
                      patientId={el?.patient_id}
                      status={el?.checkin_status}
                      lastVisitDate={el?.last_visit}
                      description={el?.visit_reason}
                      isActive={activeSlide.id === i}
                      appointmentId={el?.id}
                      img={el?.patient.photo}
                      name={
                        el?.patient?.first_name + " " + el?.patient?.last_name
                      }
                      disease={
                        el?.ckd_info?.length > 0
                          ? el?.ckd_info[0]?.ckd_code
                          : ""
                      }
                    />
                  </SwiperSlide>
                ))
              ) : (
                <Box
                  sx={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  No Appointments Found
                </Box>
              )}

              {isLoading && patientsData?.length > 9 ? (
                <SwiperSlide>
                  <Box
                    sx={{ ...flexRow, ...alignItemsCenter, height: "545px" }}>
                    <CircularProgress />
                  </Box>
                </SwiperSlide>
              ) : (
                ""
              )}
              {isLoading && patientsData?.length > 9 ? (
                <SwiperSlide>
                  <Box
                    sx={{ ...flexRow, ...alignItemsCenter, height: "545px" }}>
                    <CircularProgress />
                  </Box>
                </SwiperSlide>
              ) : (
                ""
              )}
              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
          ) : (
            !isLoading &&
            patientsData.length <= 0 && (
              <Box
                sx={{ width: "100%", height: "50vh", ...flexRowCenterCenter }}>
                <Text
                  variant="h5"
                  fontWeight="500"
                  marginTop="30px"
                  alignSelf="center">
                  {scheduleAppointmentsMessage}
                </Text>
              </Box>
            )
          )}
        </>
      ) : (
        <>
          {!activeSlide.isCalculating ? (
            <>
              {filteredPatientsData?.length ? (
                <>
                  <Box
                    style={{
                      height: "70vh",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                    onScroll={(e) => handleVerticalScroll(e)}>
                    {filteredPatientsData?.map((el, index) => (
                      <ListViewSchedules
                        openPastVisit={openPastVisit}
                        dob={calculateAge(el?.patient?.birthdate)}
                        key={index}
                        className="cards"
                        id={el?.account_id}
                        time={el?.datetime}
                        patientId={el?.patient_id}
                        status={el?.checkin_status}
                        lastVisitDate={el?.last_visit}
                        description={el?.visit_reason}
                        isActive={activeSlide.id === index}
                        appointmentId={el?.id}
                        img={el?.patient.photo}
                        name={
                          el?.patient?.first_name + " " + el?.patient?.last_name
                        }
                        disease={
                          el?.ckd_info?.length > 0
                            ? el?.ckd_info[0]?.ckd_code
                            : ""
                        }
                      />
                    ))}
                  </Box>
                  <>
                    {isLoading ? (
                      <Box
                        sx={{
                          marginBottom: "30px",
                          ...flexColumnCenterCenter,
                        }}>
                        <CircularProgress />
                      </Box>
                    ) : null}
                  </>
                </>
              ) : (
                <Box
                  sx={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.4993rem",
                    fontWeight: "500",
                  }}>
                  No Appointments found
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{ height: "70vh", overflowX: "hidden", overflowY: "scroll" }}>
              {Array(10)
                .fill("")
                .map((s, i) => (
                  <Skeleton
                    key={i}
                    variant="rounded"
                    width={"99%"}
                    height={60}
                    sx={{ marginBottom: "10px" }}
                  />
                ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default CardsContainer;
