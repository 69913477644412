import * as Yup from "yup";

export const EditTeamMemberSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  birthdate: Yup.string().required("Birth date is required"),
  national_provider_identifier: Yup.string().max(
    10,
    "Ensure this field has no more than 10 characters",
  ),
});
