import { createSlice } from "@reduxjs/toolkit";

export const initialLabsState = {
  labsSuccess: null,
  labsData: [],
  labsIsLoading: false,
  updateLabsLoading: false,
  updateLabsSuccess: null,
  labsByEncounterLoading: false,
  labsByEncounterData: [],
  labsByEncounterSuccess: null,
  perLabLoading: false,
  perLabData: [],
  perLabSuccess: null,
  getLabReqIsLoading: null,
  postLabRequestIsLoading: null,
  getLabTestsIsLoading: null,
  labRequestData: [],
  labsTestData: [],
  postLabTestIsLoading: null,
  postLabTestIsAdded: null,
  postLabRequestAdded: null,
  deleteLabTestIsLoading: null,
  deleteLabTestSuccess: null,
};

const name = "labs";

export const labsSlice = createSlice({
  name,
  initialState: initialLabsState,
  reducers: {
    getLabs: (state) => {
      state.labsIsLoading = true;
    },
    //get patient labs requested
    getLabRequestId: (state) => {
      state.getLabReqIsLoading = true;
    },
    getLabRequestIdSuccess: (state, action) => {
      state.getLabReqIsLoading = false;
      state.labRequestData = action.payload.results;
    },
    getLabRequestIdFail: (state) => {
      state.getLabReqIsLoading = false;
      state.labRequestData = [];
    },
    //get patient labs tests
    getLabTests: (state) => {
      state.getLabTestsIsLoading = true;
    },
    getLabTestsSuccess: (state, action) => {
      state.getLabTestsIsLoading = false;
      state.labsTestData = action.payload.results;
    },
    getLabTestsFail: (state) => {
      state.getLabTestsIsLoading = false;
      state.labsTestData = [];
    },
    //create lab request id
    postLabRequestId: (state) => {
      state.postLabRequestIsLoading = true;
    },
    postLabRequestIdSuccess: (state, action) => {
      state.postLabRequestIsLoading = false;
      state.postLabRequestAdded = true;
    },
    postLabRequestIdFail: (state) => {
      state.postLabRequestIsLoading = false;
      state.postLabRequestAdded = false;
    },
    //post lab test
    postLabTest: (state) => {
      state.postLabTestIsLoading = true;
    },
    postLabTestSuccess: (state, action) => {
      state.postLabTestIsLoading = false;
      state.postLabTestIsAdded = true;
    },
    postLabTestFail: (state) => {
      state.postLabTestIsLoading = false;
      state.postLabTestIsAdded = false;
    },
    //delete lab test
    deleteLabTest: (state) => {
      state.deleteLabTestIsLoading = true;
    },
    deleteLabTestSuccess: (state, action) => {
      state.deleteLabTestIsLoading = false;
      state.deleteLabTestSuccess = true;
    },
    deleteLabTestFail: (state) => {
      state.deleteLabTestIsLoading = false;
      state.deleteLabTestSuccess = false;
    },
    getLabByEncounter: (state) => {
      state.labsByEncounterLoading = true;
    },
    getPerLab: (state) => {
      state.perLabLoading = true;
    },
    getLabsSuccess: (state, action) => {
      state.labsSuccess = true;
      state.labsIsLoading = false;
      state.labsData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s?.test_name,
            value: s?.test_code,
            cpt_codes: s?.test_cpt_code,
            instruction: s?.test_cpt_code,
          }))
        : state.labsData;
    },
    getLabByEncounterSuccess: (state, action) => {
      state.labsByEncounterLoading = false;
      state.labsByEncounterSuccess = true;
      state.labsByEncounterData = action.payload.results;
    },
    getPerLabSuccess: (state, action) => {
      state.perLabLoading = false;
      state.perLabData = action.payload;
      state.perLabSuccess = true;
    },
    getLabsFail: (state) => {
      state.labsSuccess = false;
      state.labsData = [];
      state.labsIsLoading = false;
    },
    getLabByEncounterFail: (state) => {
      state.labsByEncounterLoading = false;
      state.labsByEncounterSuccess = false;
      state.labsByEncounterData = [];
    },
    getPerLabFail: (state) => {
      state.perLabLoading = false;
      state.perLabData = [];
      state.perLabSuccess = false;
    },
    clearLabsList: (state) => {
      state.labsSuccess = null;
      state.labsData = [];
      state.labsIsLoading = false;
    },
    clearLabsData: (state) => {
      state.labsSuccess = null;
      state.labsData = [];
      state.labsIsLoading = false;
      state.updateLabsLoading = false;
      state.updateLabsSuccess = null;
      state.labsByEncounterLoading = false;
      state.labsByEncounterData = [];
      state.labsByEncounterSuccess = null;
      state.getLabReqIsLoading = null;
      state.postLabRequestIsLoading = null;
      state.getLabTestsIsLoading = null;
      state.labRequestData = [];
      state.labsTestData = [];
      state.postLabTestIsLoading = null;
      state.postLabTestIsAdded = null;
    },
    clearLabsTestData: (state) => {
      state.postLabTestIsLoading = null;
      state.postLabTestIsAdded = null;
      state.postLabRequestAdded = null;
      state.deleteLabTestIsLoading = null;
      state.deleteLabTestSuccess = null;
    },
  },
});

export const { getlabs, clearLabsData, clearLabsTestData, clearLabsList } =
  labsSlice.actions;

export default labsSlice.reducer;

export const getLabsList = (searchQuery, signal) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `api/compendium-lab-tests/?prefix=${searchQuery}`,
    signal: signal,
  },
  type: "getLabs",
});

export const getLabsByEncounter = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/laborders/search/?encounter_id=${id}`,
  },
  type: "getLabByEncounter",
});

export const getPerLab = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/laborders/${id}/`,
  },
  type: "getPerLab",
});

export const postLabRequestId = (patientId, data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/patients/${patientId}/labrequest/`,
  },
  type: "postLabRequestId",
});

export const postPutLabTest = (patientId, data, method, labtestId) => ({
  payload: {
    apiName: name,
    method: method,
    data,
    url:
      method == "post"
        ? `/api/patients/${patientId}/labtestrequest/`
        : `/api/patients/${patientId}/labtestrequest/${labtestId}/`,
  },
  type: "postLabTest",
});

export const deleteLabTest = (patientId, labtestId) => ({
  payload: {
    apiName: name,
    method: "delete",
    url: `/api/patients/${patientId}/labtestrequest/${labtestId}/`,
  },
  type: "deleteLabTest",
});

export const getLabTests = (patientId, encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/labtestrequest/?encounter_pk=${encounterId}`,
  },
  type: "getLabTests",
});

export const getLabRequestId = (patientId, encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/labrequest/?encounter_pk=${encounterId}`,
  },
  type: "getLabRequestId",
});
