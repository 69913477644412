import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { checkNullOrUndefinedString } from "utils/helper";
import { getGroupedFormattedLabs } from "utils/labs/utils";

const LabResultDetailCard = ({ data, marginTop, pastVisit }) => {
  const [groupedFormattedLabsData, setGroupedFormattedLabsData] = useState([]);

  useEffect(() => {
    setGroupedFormattedLabsData(getGroupedFormattedLabs(data)?.labGroups);
  }, [data]);

  return (
    <Box sx={{ marginTop: marginTop }}>
      {data !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Box
                  data-testid="labresults-heading"
                  sx={{
                    color: "#2D3748",
                    fontSize: "20px",
                    lineHeight: "28.79px",
                    fontWeight: "700",
                  }}>
                  <span style={{ borderBottom: "2px solid  #1344F1" }}>
                    Lab Results
                  </span>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              borderRadius: "10px",
              marginTop: "10px",
            }}>
            <Box
              data-testid="labresults-tests-details"
              sx={{ maxHeight: "490px", marginTop: "10px", overflowY: "auto" }}>
              <Box>
                {Object.entries(groupedFormattedLabsData)?.map(
                  (group, groupIndex) => (
                    <Box
                      key={`group-${groupIndex}`}
                      sx={{ marginBottom: "20px" }}>
                      <Text variant="h2" sx={{ marginBottom: "10px" }}>
                        {group?.[0]}:
                      </Text>

                      <Box
                        sx={{
                          marginLeft: "20px",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "10px",
                        }}>
                        {group?.[1]?.map(
                          ({ code, val, at_risk, range }, itemIndex) => (
                            <Box
                              key={`item-${groupIndex}-${itemIndex}`}
                              marginY={1}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}>
                                <Text
                                  data-testid={`lab-test-name-${itemIndex}`}
                                  variant="h3"
                                  minWidth="160px">
                                  {code ?? ""}
                                </Text>
                                <Text
                                  variant="bodyXs"
                                  marginLeft="20px"
                                  data-testid={`lab-value-${itemIndex}`}
                                  color={at_risk === "normal" ? "" : "red"}>
                                  {checkNullOrUndefinedString(
                                    Array.isArray(val) && val.length > 1
                                      ? `${val.join("-")}`
                                      : val,
                                  )}
                                </Text>
                              </Box>
                              {checkNullOrUndefinedString(range) ? (
                                <Text variant="formLabel">
                                  Ref:
                                  <Text
                                    variant="formLabel"
                                    data-testid={`lab-range-${itemIndex}`}>
                                    &nbsp; {range ?? ""}
                                  </Text>
                                </Text>
                              ) : (
                                ""
                              )}
                            </Box>
                          ),
                        )}
                      </Box>
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;
