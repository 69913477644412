import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addFeedback,
  fetchFeedbacksForPatientEncounter,
} from "apiClients/feedback";
import { useEffect } from "react";

export const useFeedback = (patientId, encounterId) => {
  const queryClient = useQueryClient();
  const mutationFeedback = useMutation({
    mutationFn: async (payload) => {
      return addFeedback(payload);
    },
    onSuccess: (newFeedback) => {
      // Update the cache directly without invalidating
      queryClient.setQueryData(
        ["feedbacks", patientId, encounterId],
        (oldFeedbacks = []) => {
          return [...oldFeedbacks, newFeedback];
        },
      );
    },
  });

  const {
    data: feedbackList,
    isLoading: isFetching,
    refetch,
  } = useQuery({
    queryKey: ["feedbacks", patientId, encounterId],
    staleTime: 10 * 60 * 1000, // 10 minutes stale time
    queryFn: async ({ signal }) =>
      await fetchFeedbacksForPatientEncounter(patientId, encounterId, {
        signal,
      }),
    enabled: !!patientId,
  });

  useEffect(() => {
    // Refetch whenever keys change
    refetch();
    return () => {
      // Invalidate the query on unmount to trigger abort
      queryClient.invalidateQueries(["feedbacks", patientId, encounterId]);
    };
  }, [queryClient, patientId, encounterId, refetch]);

  return { feedbackList, isFetching, mutationFeedback };
};
