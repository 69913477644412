import { createSlice } from "@reduxjs/toolkit";

export const initialAllHistoryState = {
  allHistorySuccess: false,
  allHistoryData: {},
  allHistoryIsLoading: false,
};

const name = "allHistory";

export const allHistorySlice = createSlice({
  name,
  initialState: initialAllHistoryState,
  reducers: {
    getAllHistory: (state, action) => {
      state.allHistoryIsLoading = true;
    },
    getAllHistorySuccess: (state, action) => {
      state.allHistorySuccess = true;
      state.allHistoryIsLoading = false;
      state.allHistoryData = action.payload;
    },
    getAllHistoryFail: (state, action) => {
      state.allHistorySuccess = false;
      state.allHistoryData = {};
      state.allHistoryIsLoading = false;
    },
    clearAllHistoryData: (state, action) => {
      state.allHistorySuccess = false;
      state.allHistoryData = {};
      state.allHistoryIsLoading = false;
    },
  },
});

export const { getAllHistory, clearAllHistoryData } = allHistorySlice.actions;

export default allHistorySlice.reducer;

export const getAllHistoryList = (patientId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientId}/all-history/`,
  },
  type: "getAllHistory",
});
