import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from "@lexical/markdown";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { EDITOR_TRANSFORMERS } from "../MarkDownTransformers";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

const ConversionsPlugin = forwardRef(({ data }, ref) => {
  const [editor] = useLexicalComposerContext();

  const convertMarkDownToLexical = useCallback(
    (data) => {
      editor.update(() => {
        $convertFromMarkdownString(
          data,
          EDITOR_TRANSFORMERS,
          undefined, // node
          true,
        );
      });
    },
    [editor],
  );

  const convertLexicalFormatToMarkDown = useCallback(() => {
    const markdown = editor.getEditorState().read(() => {
      return $convertToMarkdownString(EDITOR_TRANSFORMERS, undefined, true);
    });
    return markdown;
  }, [editor]);

  useImperativeHandle(ref, () => ({
    getPlanNotesInMarkdownFormat() {
      return convertLexicalFormatToMarkDown();
    },
  }));

  useEffect(() => {
    convertMarkDownToLexical(data);
  }, [data]);

  return <></>;
});
export default ConversionsPlugin;
