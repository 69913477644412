import { Divider } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { SubForm } from "../common/SubForm";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMedicalEncounter,
  deleteMedicalEncounter,
  fetchAllMedicalEncounters,
  updateMedicalEncounter,
} from "apiClients/medicalEncounters";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { Required } from "validations/validators";
import moment from "moment";

const EncounterType = Object.freeze({
  HOSPITAL: "Hospital",
  ER: "Emergency Room",
  URGENT_CARE: "Urgent Care",
  OTHER: "Other",
});

const inputs = [
  {
    label: "Type of encounter",
    key: "encounter_type",
    type: "select",
    options: [
      { value: EncounterType.HOSPITAL, label: EncounterType.HOSPITAL },
      { value: EncounterType.ER, label: EncounterType.ER },
      { value: EncounterType.URGENT_CARE, label: EncounterType.URGENT_CARE },
      { value: EncounterType.OTHER, label: EncounterType.OTHER },
    ],
  },
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Reason",
    key: "reason",
    cols: 4,
  },
  {
    label: "Date of admission",
    key: "date",
    date: true,
    cols: 4,
    max: moment().format("YYYY-MM-DD"),
    validators: [Required],
  },
  {
    label: "Date of discharge",
    key: "end_date",
    date: true,
    cols: 4,
  },
];

export const RecentMedicalEncounters = memo(() => {
  const queryClient = useQueryClient();
  const [values, setValues] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medical encounter.
          return deleteMedicalEncounter(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medical encounter since it is dirty (changed).
          return updateMedicalEncounter(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medical encounter and we need to add it.
        return addMedicalEncounter(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medicalEncounters", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["medicalEncounters", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["medicalEncounters", patientId],
    queryFn: () => fetchAllMedicalEncounters(patientId),
  });

  const handleSubmit = (dirtyValues) => {
    const operations = dirtyValues.map((value) => {
      const mapped = {
        ...value,
        // If the end_date is not set, we need to set it to null because "" does not validate with the backend.
        end_date: value.end_date || null,
      };
      return mutation.mutateAsync(mapped);
    });
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (data?.results) {
      const mapped = data.results.map((result) => {
        return {
          ...result,
          // Make sure the date is existing or a string so the input is controlled.
          end_date: result.end_date ?? "",
        };
      });
      setValues(mapped);
      registerForm("medicalEncounters", handleSubmit, mapped);
    }
  }, [data]);

  return (
    <>
      <Text variant="h1">Recent Medical Encounters</Text>
      <Divider sx={{ my: 2 }} />
      <SubForm
        dataLoading={isLoading}
        formKey={"medicalEncounters"}
        subSections={inputs}
        data={values}
      />
    </>
  );
});
