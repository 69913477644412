import { createContext, useState } from "react";

export const IntakeFormContext = createContext({
  handlers: [],
  registerForm: () => {},
});

// This will be used to keep a track of the initial values of the form so that we can do a proper dirty check on the values during form submission.
export const IntakeFormProvider = ({ children, patientId }) => {
  const [initialValues, setInitialValuesState] = useState({});
  const [mappers, setMappers] = useState({});
  const [handlers, setHandlers] = useState({});

  const registerForm = (key, submitHandler, values, mapper) => {
    setHandlers((prevHandlers) => ({ ...prevHandlers, [key]: submitHandler }));
    setInitialValuesState((prevValues) => ({
      ...prevValues,
      [key]: values,
    }));

    if (mapper) {
      setMappers((prevMappers) => ({ ...prevMappers, [key]: mapper }));
    }
  };

  return (
    <IntakeFormContext.Provider
      value={{ patientId, initialValues, mappers, handlers, registerForm }}>
      {children}
    </IntakeFormContext.Provider>
  );
};
